import React, { Component, Fragment } from 'react';
import { get, isEmpty, replace, toNumber, isNaN } from 'lodash';
import { NumericFormat as NumberFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import { Toggle, ToggleContainer } from '../../common/components/toggle';
import { CheckboxTxtInput } from '../../common/components/checkbox-text-input';
import { FileDropzoneComponent } from '../../common/components/file-dropzone';
import { YesNoRadio } from '../../common/components/yes-no-radio';
import { DropdownContainer } from '../../common/components/dropdown-container';
import { ProcessorDropdownComponent } from '../../common/components/processor-dropdown';
import ZeroAsEmptyNumberFormat from '../../common/components/zero-as-empty-number-format/ZeroAsEmptyNumberFormat';
import { isFd, isElavon } from '../../common/utilities';

export default class USSetupForm extends Component {
	dotRegex = /\./g;
	fixFieldName = field => replace(field, this.dotRegex, '_');

	handleToggleCheckboxChange = e => {
		e.target.checked = !e.target.checked;
		this.props.handleChange(e);
	};

	getPercentage = key => parseFloat(this.props.setup.processingDetails[`${key}TransactionsPercentage`] || 0);

	getTotalProcessing = () => {
		const { setup } = this.props;
		if (!setup) return 0;
		return this.getPercentage('swiped') + this.getPercentage('keyed') + this.getPercentage('internet');
	};

	checkIfAllowedValue = (min, max) => ({ value }) => {
		if (isNaN(toNumber(value))) return 0 >= min && 0 <= max;
		return toNumber(value) >= min && toNumber(value) <= max;
	};

	handlePSDependantValuesChanged = event => {
		const { value, name } = event.target;
		const { proposedSavings } = this.props.setup;
		const proposedSavingsEvent = { target: { name: 'proposedSavings', value: null } };

		const defaultAmountValues = {
			amexSavingsPercentage: 0,
			combinedSavingsPercentage: 0,
			bankcardSavingsPercentage: 0,
			monthlyDollarSavingsAmounts: 0,
		};
		if (name === 'proposedSavings_type') {
			proposedSavingsEvent.target.value = {
				...defaultAmountValues,
				type: value,
				rateReductionType: 'Unknown',
			};
		} else if (name === 'proposedSavings_rateReductionType') {
			proposedSavingsEvent.target.value = {
				...defaultAmountValues,
				rateReductionType: value,
				type: proposedSavings.type,
			};
		} else if (name === 'additionalSetupDetails_isCurrentlyAcceptingCreditCards') {
			proposedSavingsEvent.target.value = {
				...defaultAmountValues,
				type: 'Unknown',
				rateReductionType: 'Unknown',
			};
			this.props.handleChange(event, () => this.props.handleChange(proposedSavingsEvent));
			return;
		}
		this.props.handleChange(proposedSavingsEvent);
	};

	render() {
		const { setup } = this.props;
		return (
			<ToggleContainer>
				{this.renderGeneralSection(setup)}
				{this.renderProposedSavingsSection(setup)}
				{this.renderPricingStructureSection(setup)}
				{this.renderAmexEntitlementSection(setup)}
				{this.renderProposedFeesSection(setup)}
				{this.renderEIDSEnrollmentSection(setup)}
				{this.renderInternalNotes(setup)}
			</ToggleContainer>
		);
	}

	renderField(field, label, placeholder) {
		const { setup } = this.props;
		const name = this.fixFieldName(field);
		return (
			<div className="form__field spc--bottom--med">
				<label className="label" htmlFor={name}>
					{label}{' '}
				</label>
				<input
					id={name}
					name={name}
					type="text"
					placeholder={placeholder}
					className="input input--med"
					value={get(setup, field)}
					onChange={this.props.handleChange}
				/>
			</div>
		);
	}

	renderInternalNotes() {
		return (
			<Card title="Internal Notes" tooltip="This will be included in the Setup Form sent to Applications.">
				{this.renderField('internalNotes', 'Note', 'Notes')}
			</Card>
		);
	}

	renderEIDSEnrollmentSection(setup) {
		if (!setup.eidsEnrollmentDetails || !isFd(setup)) return null;
		return (
			<Card title="EIDS Enrollment" showSeparator={true}>
				<div>
					<div className="required">
						<label className="label">Enroll with EIDS (Electronic Integrated Disputes SystemSM)</label>
						<DropdownContainer
							type="radio"
							selectName="eidsEnrollmentDetails_shouldEnrollEids"
							toBooleanTrueFalse={true}
							value={setup.eidsEnrollmentDetails.shouldEnrollEids}
							onChange={this.props.handleChange}
							labelText=""
							options={[
								{
									value: true,
									text: 'Yes',
									children: (
										<div className="row">
											<div className="col col-sml-12 col-lrg-6 spc--bottom--sml">
												<div className="spc--bottom--sml">
													<label className="label" htmlFor="eidsEnrollmentDetails_eidsMerchantPrice">
														Enter price to bill merchant (cost is $3.95):
													</label>
													<div>
														<input
															className="input input--med"
															id="eidsEnrollmentDetails_eidsMerchantPrice"
															name="eidsEnrollmentDetails_eidsMerchantPrice"
															type="decimal"
															value={setup.eidsEnrollmentDetails.eidsMerchantPrice}
															onChange={this.props.handleChange}
														/>
													</div>
												</div>
												<div className="spc--bottom--sml">
													<div className="message message--default message--default--alt">
														Please note, if you would like to bill more than $5.95, you'll need to obtain a signed form
														from the merchant authorizing the charge. Banking Services will follow up with you
														accordingly for a signature.
													</div>
												</div>
											</div>

											<div className="col col-sml-12 col-lrg-6 spc--bottom--sml">
												<div className="spc--bottom--sml required">
													<label className="label" htmlFor="eidsEnrollmentDetails_eidsMerchantEmail">
														Enter the email the login credentials will be sent to:
													</label>
													<div>
														<input
															className="input input--med"
															id="eidsEnrollmentDetails_eidsMerchantEmail"
															name="eidsEnrollmentDetails_eidsMerchantEmail"
															type="text"
															value={setup.eidsEnrollmentDetails.eidsMerchantEmail}
															onChange={this.props.handleChange}
														/>
													</div>
												</div>
												<div className="spc--bottom--sml">
													<div className="message message--default message--default--alt">
														It usually takes up to 2-3 days to get an approval and before the dispute reporting kicks
														in.
													</div>
												</div>
											</div>
										</div>
									),
								},
								{ value: false, text: 'No' },
							]}
							wrapperClass="flex flex--primary spc--bottom--sml"
							divClass=""
						/>
					</div>
				</div>
			</Card>
		);
	}

	renderProposedFee(setup, fee, label, required) {
		const name = `proposedFees_${fee}`;
		return (
			<div className="col col-sml-12 col-med-3">
				<div id={`${name}_div`} className={`form__field spc--bottom--med${required ? ' required' : ''}`}>
					<label className="label">{label} </label>
					{this.renderAmountInput(setup.proposedFees[fee], name)}
				</div>
			</div>
		);
	}

	renderRetrievalCharge(setup) {
		return this.renderProposedFee(setup, 'retrievalCharge', 'Retrieval Charge', false);
	}

	renderPciFee(setup) {
		return this.renderProposedFee(setup, 'pciAnnualFee', 'PCI Annual Fee', false);
	}

	renderProposedFeesSection(setup) {
		return (
			<Card title="Proposed Fees">
				<div className="row">
					{this.renderProposedFee(setup, 'batchFee', 'Batch Fee', false)}
					{this.renderProposedFee(setup, 'statementFee', 'Statement Fee', true)}
					{this.renderProposedFee(setup, 'annualFee', 'Annual Fee', false)}
					{this.renderProposedFee(setup, 'chargebackFee', 'Chargeback Fee', true)}
					{this.renderRetrievalCharge(setup)}
					{this.renderProposedFee(setup, 'monthlyMinimumFee', 'Monthly Minimum Fee', true)}
					{this.renderPciFee(setup)}
					{setup.doesAcceptEbt ? (
						<div className="col col-sml-12 col-med-3">
							<div className="form__field spc--bottom--med required">
								<label className="label">EBT Transaction Fee </label>
								{this.renderAmountInput(setup.proposedFees.ebtTransactionFee, 'proposedFees_ebtTransactionFee')}
							</div>
						</div>
					) : null}
					<div className="col col-sml-12 col-med-6">
						<label className="label">Early Termination Fee (ETF)</label>
						<div className="row row-align-middle">
							<div className="col col-sml-12 col-med-6 spc--bottom--med">
								<CheckboxTxtInput
									labelClass="display--n"
									checkboxName="proposedFees_isEarlyTerminationFeeWaived"
									inputName="proposedFees_earlyTerminationFee"
									placeholder="$0"
									checked={!setup.proposedFees.isEarlyTerminationFeeWaived}
									value={String(setup.proposedFees.earlyTerminationFee)}
									onCheckChanged={this.handleToggleCheckboxChange}
									onValueChanged={this.props.handleChange}
									format="NumberFormat"
								/>
							</div>
							<div className="col col-sml-12 col-med-6 spc--bottom--med">
								<input
									className="input--check"
									type="checkbox"
									id="proposedFees_isEarlyTerminationFeeWaived"
									name="proposedFees_isEarlyTerminationFeeWaived"
									checked={setup.proposedFees.isEarlyTerminationFeeWaived}
									onChange={this.props.handleChange}
								/>
								<label htmlFor="proposedFees_isEarlyTerminationFeeWaived" className="label">
									Waived
								</label>
							</div>
						</div>
					</div>
				</div>
				{this.renderOnlineReportingSection()}
			</Card>
		);
	}

	renderOnlineReportingSection() {
		const { setup, alreadyUploadedTaxExemption } = this.props;
		if (!setup || !isFd(setup)) return null;
		return (
			<React.Fragment>
				{isFd(setup) && (
					<Fragment>
						<div className="separator separator--grey1 spc--bottom--med"></div>
						<h6 className="label type--color--text--dark spc--bottom--med type--wgt--semibold">
							Online reporting (Check whichever is applicable)
						</h6>
					</Fragment>
				)}
				<div className="row f--a--c">
					{isFd(setup) && (
						<div className="col col-sml-12 col-med-6 spc--bottom--sml">
							<input
								className="input--check"
								type="checkbox"
								name="onlineReportingOptions_isEnabled"
								id="onlineReportingOptions_isEnabled"
								checked={setup.onlineReportingOptions.isEnabled}
								onChange={this.props.handleEnableAccessOneChange}
							/>
							<label className="label" htmlFor="onlineReportingOptions_isEnabled">
								Enable Access One
							</label>
						</div>
					)}
					{setup.onlineReportingOptions.isEnabled && (
						<div className="col col-sml-12 spc--bottom--med">
							<span className="label spc--bottom--sml" id="onlineReportingOptions_markupFee_div">
								Price:<span className="type--color--primary">*</span>
							</span>
							<div className="spc--bottom--tny">
								<input
									type="radio"
									className="input--radio"
									id="radMarkupFeeFree"
									name="onlineReportingOptions_markupFee"
									value="0"
									checked={setup.onlineReportingOptions.markupFee === '0'}
									onChange={this.props.handleChange}
								/>
								<label className="label" htmlFor="radMarkupFeeFree">
									Free ($0)
								</label>
							</div>
							<div className="spc--bottom--tny">
								<input
									type="radio"
									className="input--radio"
									id="radMarkupFee5"
									name="onlineReportingOptions_markupFee"
									value="5"
									checked={setup.onlineReportingOptions.markupFee === '5'}
									onChange={this.props.handleChange}
								/>
								<label className="label" htmlFor="radMarkupFee5">
									$5
								</label>
							</div>
							<div>
								<input
									type="radio"
									className="input--radio"
									id="radMarkupFee10"
									name="onlineReportingOptions_markupFee"
									value="10"
									checked={setup.onlineReportingOptions.markupFee === '10'}
									onChange={this.props.handleChange}
								/>
								<label className="label" htmlFor="radMarkupFee10">
									$10
								</label>
							</div>
						</div>
					)}
					<div className="col col-sml-12 spc--bottom--med">
						<input
							className="input--check"
							type="checkbox"
							name="additionalSetupDetails_isTaxExemptionRequested"
							id="isTaxExemptionRequested"
							checked={setup.additionalSetupDetails.isTaxExemptionRequested}
							onChange={this.props.handleChange}
						/>
						<label className="label" htmlFor="isTaxExemptionRequested">
							Request tax exemption
						</label>
					</div>
					{setup.additionalSetupDetails.isTaxExemptionRequested && (
						<div className="col col-sml-12 col-med-6 spc--bottom--sml">
							<div className="form__field required">
								<label className="label">Upload Tax Document </label>
								<div className="file-upload--sml">
									<FileDropzoneComponent
										multiple={true}
										tag="TaxExemption"
										fileList={this.props.files}
										onDrop={this.props.onDropFile}
										onRemoveFile={(fileType, i) =>
											this.props.handleRemoveFile(fileType, i, this.props.validateTaxExemptionFiles)
										}
										validateFileSize={false}
									/>
								</div>
							</div>
						</div>
					)}
					{setup.additionalSetupDetails.isTaxExemptionRequested && !isEmpty(alreadyUploadedTaxExemption) && (
						<div className="col col-sml-12">
							<div className="note note--default">Tax Exempt File already uploaded.</div>
						</div>
					)}
				</div>
				<div className="row"></div>
			</React.Fragment>
		);
	}

	renderProcessorDropdown() {
		const { setup, processorList } = this.props;
		return (
			<ProcessorDropdownComponent
				className="input input--med input--select"
				id="processorId"
				value={setup.processorId}
				processorList={processorList}
				setup={setup}
				onChange={this.props.handleProcessorChange}
				merchId={setup.appId}
			/>
		);
	}

	renderProposedStructure() {
		const { setup } = this.props;
		return (
			<div className="col col-sml-12 col-lrg-4">
				<div className="form__field">
					<label className="label">Proposed Structure </label>
					<select
						className="input input--med input--select"
						name="pricingStructure_pinDebitStructureType"
						value={setup.pricingStructure.pinDebitStructureType}
						onChange={this.props.handleChange}
					>
						<option>Please select</option>
						<option value="PassThrough">Pass Through</option>
						<option value="FlatRate">Flat Rate</option>
					</select>
				</div>
			</div>
		);
	}

	renderPinDebitStructure() {
		const { setup } = this.props;
		return (
			<div className="row grid-align-middle spc--bottom--med">
				{this.renderProposedStructure()}
				<div className="col col-sml-12 col-lrg-4">
					<div className="form__field">
						<label className="label">PIN Based Debit Trans Fee </label>
						{this.renderNumberInput(
							setup.pricingStructure.pinDebitTransactionFee,
							'pricingStructure_pinDebitTransactionFee',
							'$'
						)}
					</div>
				</div>
				<div className="col col-sml-12 col-lrg-4">
					<div className="form__field">
						<label className="label">PIN Based Debit Markup </label>
						{this.renderNumberInput(
							setup.pricingStructure.pinDebitMarkupAmount,
							'pricingStructure_pinDebitMarkupAmount',
							'%',
							[0, 100]
						)}
					</div>
				</div>
			</div>
		);
	}

	renderPinDebit() {
		const { setup } = this.props;
		return (
			<div className="required">
				<label className="label">Setup merchant with Pin Debit </label>
				<YesNoRadio
					name="pricingStructure_shouldSetupPinDebit"
					yes={setup.pricingStructure.shouldSetupPinDebit}
					onChange={this.props.handleChange}
				/>
				{setup.pricingStructure.shouldSetupPinDebit && this.renderPinDebitStructure()}
			</div>
		);
	}

	renderPricingStructureSection(setup) {
		return (
			<Card title="Pricing Structure">
				<div className="row">
					<div className="col col-sml-12 col-med-6">
						<div id="processor_div" className="form__field spc--bottom--med required">
							<label className="label">Bank</label>
							{this.renderProcessorDropdown()}
						</div>
					</div>
				</div>
				<div className="separator separator--grey1 spc--bottom--med"></div>
				<div className="row">
					<div className="col col-sml-12 col-med-6">
						{this.renderRateStructure('pricingStructure', '', false, setup.pricingStructure.pricingStructureType)}
					</div>
				</div>
				<div className="separator separator--grey1 spc--bottom--med"></div>
				<div className="row">
					<div className="col col-sml-12 col-med-6">
						<div className="form__field spc--bottom--med">
							<label className="label">Authorization Fee </label>
							{this.renderNumberInput(
								setup.pricingStructure.authorizationFee,
								'pricingStructure_authorizationFee',
								'$0'
							)}
						</div>
					</div>
					<div className="col col-sml-12 col-med-6">
						<div className="form__field spc--bottom--med">
							<label className="label">V/MC/Disc Transaction Fee </label>
							{this.renderNumberInput(
								setup.pricingStructure.bankCardTransactionFee,
								'pricingStructure_bankCardTransactionFee',
								'$0'
							)}
						</div>
					</div>
				</div>
				{this.renderPinDebit()}
				{!setup || isFd(setup) ? (
					<div className="row">
						<div className="col col-sml-12 col-med-6">
							<div className="form__field spc--bottom--sml required">
								<label className="label">Discount Method </label>
								<select
									className="input input--med input--select"
									name="pricingStructure_discountMethod"
									value={setup.pricingStructure.discountMethod}
									onChange={this.props.handleChange}
								>
									<option>Please select</option>
									<option value="Monthly">Monthly</option>
									<option value="Daily">Daily</option>
								</select>
							</div>
						</div>
					</div>
				) : null}
				{setup && isElavon(setup) ? (
					<Fragment>
						<div className="row">
							<div className="col col-sml-12 col-med-6 spc--bottom--sml">
								<input
									className="input--check"
									type="checkbox"
									id="pricingStructure_shouldRequireNextDayFunding"
									name="pricingStructure_shouldRequireNextDayFunding"
									value={setup.pricingStructure.shouldRequireNextDayFunding}
									onChange={this.props.handleChange}
								/>
								<label htmlFor="pricingStructure_shouldRequireNextDayFunding" className="label">
									Next Day Funding Required ($5 monthly fee applies)
								</label>
							</div>
						</div>
						<div className="separator separator--grey1 spc--bottom--med"></div>
					</Fragment>
				) : null}
			</Card>
		);
	}

	renderProposedSavingsSection(setup) {
		if (!get(setup, 'additionalSetupDetails.isCurrentlyAcceptingCreditCards')) return null;
		return (
			<Card title="Proposed Savings (only if applicable)">
				<div className="row">
					<div className="col col-sml-12 col-med-6">
						<div>
							<DropdownContainer
								labelText="Proposed Savings Type"
								labelClass="label"
								selectClass="input input--med input--select"
								wrapperClass="form__field spc--bottom--med"
								selectName="proposedSavings_type"
								value={setup.proposedSavings.type}
								onChange={this.handlePSDependantValuesChanged}
								options={[
									{
										value: 'Unknown',
										text: 'Please select',
										default: true,
										children: <React.Fragment></React.Fragment>,
									},
									{
										value: 'MonthlyDollarSavings',
										text: 'Monthly Dollar Savings',
										children: (
											<React.Fragment>
												<div className="form__field spc--bottom--med required">
													<label className="label">Monthly Dollar Savings</label>
													{this.renderNumberInput(
														setup.proposedSavings.monthlyDollarSavingsAmounts,
														'proposedSavings_monthlyDollarSavingsAmounts',
														'$'
													)}
												</div>
											</React.Fragment>
										),
									},
									{
										value: 'EffectiveRateReduction',
										text: 'Effective Rate Reduction',
										children: (
											<DropdownContainer
												labelText="Effective Rate Reduction Type"
												required={true}
												labelClass="label"
												selectClass="input input--med input--select"
												wrapperClass="form__field spc--bottom--med"
												selectName="proposedSavings_rateReductionType"
												value={setup.proposedSavings.rateReductionType || ''}
												onChange={this.handlePSDependantValuesChanged}
												options={[
													{ value: 'Unknown', text: 'Please select', default: true, children: null },
													{
														value: 'Combined',
														text: 'Combined',
														children: (
															<React.Fragment>
																<div className="row spc--bottom--med">
																	<div className="col col-sml-12 col-med-6 required">
																		<label className="label">Combined</label>
																		{this.renderNumberInput(
																			setup.proposedSavings.combinedSavingsPercentage,
																			'proposedSavings_combinedSavingsPercentage',
																			'%',
																			[0, 100]
																		)}
																	</div>
																</div>
															</React.Fragment>
														),
													},
													{
														value: 'Individual',
														text: 'Individual',
														children: (
															<React.Fragment>
																<div className="row spc--top--med">
																	<div className="col col-sml-12 col-med-6">
																		<div className="form__field required">
																			<label className="label">Bankcard</label>
																			{this.renderNumberInput(
																				setup.proposedSavings.bankcardSavingsPercentage,
																				'proposedSavings_bankcardSavingsPercentage',
																				'%',
																				[0, 100]
																			)}
																		</div>
																	</div>
																	<div className="col col-sml-12 col-med-6">
																		<div className="form__field required">
																			<label className="label">Amex</label>
																			{this.renderNumberInput(
																				setup.proposedSavings.amexSavingsPercentage,
																				'proposedSavings_amexSavingsPercentage',
																				'%',
																				[0, 100]
																			)}
																		</div>
																	</div>
																</div>
															</React.Fragment>
														),
													},
												]}
											></DropdownContainer>
										),
									},
								]}
							></DropdownContainer>
						</div>
					</div>
				</div>
			</Card>
		);
	}

	renderAmexEntitlementSection(setup) {
		return (
			<Card title="Amex">
				<div className="required">
					<label className="label">Amex Entitlement</label>
					<div className="flex flex--primary spc--bottom--sml">
						<div className="spc--right--med">
							<input
								className="input--radio"
								type="radio"
								id="amexDetails_status_yes"
								name="amexDetails_status_yes"
								checked={setup.amexDetails.status && setup.amexDetails.status !== 'None'}
								value="New"
								onChange={this.props.handleAmexChange}
							/>
							<label className="label" htmlFor="amexDetails_status_yes">
								Yes
							</label>
						</div>
						<div>
							<input
								className="input--radio"
								type="radio"
								id="amexDetails_status"
								name="amexDetails_status"
								value="None"
								checked={setup.amexDetails.status === 'None'}
								onChange={this.props.handleAmexChange}
							/>
							<label className="label" htmlFor="amexDetails_status">
								No
							</label>
						</div>
					</div>
					{setup.amexDetails.status && setup.amexDetails.status !== 'None' && (
						<DropdownContainer
							type="radio"
							selectName="amexDetails_program"
							value={setup.amexDetails.program}
							onChange={this.props.handleProgramChange}
							labelText=""
							options={[
								{ value: 'OptBlue', text: 'Opt Blue', children: this.renderOptBlue() },
								{ value: 'ESA', text: 'Amex ESA', children: this.renderAmex() },
							]}
							wrapperClass="display--n"
							divClass="border--top padd--top--med"
						/>
					)}
				</div>
			</Card>
		);
	}

	renderGeneralSection(setup) {
		const additionalSetupDetails = get(setup, 'additionalSetupDetails', {});
		const isCardknox = get(setup, 'telemarketerList.length', 0) > 0;
		const telemarketerLabel = isCardknox ? 'Rep 2' : 'Rep 2 Email';
		const total = this.getTotalProcessing();
		return (
			<Card title="General">
				<div className="row f--a--c">
					<div className="col col-sml-12 col-med-6">
						<div className={`form__field ${isCardknox ? 'required' : ''}`}>
							<label className="label">{telemarketerLabel}</label>
							{isCardknox ? (
								<select
									placeholder="Telemarketer"
									className="input input--med input--select"
									name="telemarketers"
									value={get(
										setup.telemarketerList.find(x => x.agentName === setup.telemarketerEmail),
										'agentName',
										''
									)}
									onChange={this.props.handleTelemarketerChange}
								>
									<option value="">Please select...</option>
									{setup.telemarketerList.map(telemarketer => (
										<option key={telemarketer.agentId}>{telemarketer.agentName}</option>
									))}
								</select>
							) : (
								<input
									type="text"
									placeholder="Rep 2 Email"
									className="input input--med"
									name="telemarketerEmail"
									value={setup.telemarketerEmail.trim()}
									onChange={this.props.handleChange}
								></input>
							)}
						</div>
					</div>
				</div>

				<div className="spc--bottom--med">
					<label className="card--primary__subtitle">Check whichever is applicable</label>
					<div className="required">
						<label className="label">The signer or business has, or has previously had, an account with Fidelity</label>
						<YesNoRadio
							name="hasExistingFidelityAccount"
							yes={setup.hasExistingFidelityAccount}
							onChange={this.props.handleChange}
						/>
						{setup.hasExistingFidelityAccount && (
							<div className="row spc--bottom--med">
								<div className="col col-sml-12 col-med-6">
									<label className="label">DBA/MID </label>
									<input
										type="text"
										placeholder="DBA/MID of existing account"
										className="input input--med"
										name="existingAccountDba"
										value={setup.existingAccountDba}
										onChange={this.props.handleChange}
									></input>
								</div>
							</div>
						)}
					</div>
					<div className="separator--grey1 spc--bottom--med"></div>
					<div className="required">
						<label className="label">Merchant currently accepts credit cards</label>
						<YesNoRadio
							name="additionalSetupDetails_isCurrentlyAcceptingCreditCards"
							yes={additionalSetupDetails.isCurrentlyAcceptingCreditCards}
							onChange={this.handlePSDependantValuesChanged}
						/>
					</div>
					<div>
						{additionalSetupDetails.isCurrentlyAcceptingCreditCards && (
							<YesNoRadio
								name="additionalSetupDetails_hadStatementsReviewed"
								yes={additionalSetupDetails.hadStatementsReviewed}
								onChange={this.props.handleChange}
								yesLabel="Statements reviewed"
								noLabel="No statements reviewed"
							/>
						)}
						{additionalSetupDetails.isCurrentlyAcceptingCreditCards === false && (
							<YesNoRadio
								name="additionalSetupDetails_requiresPricingAnalysis"
								yes={additionalSetupDetails.requiresPricingAnalysis}
								onChange={this.props.handleChange}
								yesLabel="Standard industry rates"
								noLabel="Use rates provided by agent"
							/>
						)}
					</div>
					{additionalSetupDetails.isCurrentlyAcceptingCreditCards && !additionalSetupDetails.hadStatementsReviewed && (
						<YesNoRadio
							name="additionalSetupDetails_requiresPricingAnalysis"
							yes={additionalSetupDetails.requiresPricingAnalysis}
							onChange={this.props.handleChange}
							yesLabel="Standard industry rates"
							noLabel="Use rates provided by agent"
						/>
					)}
					{additionalSetupDetails.isCurrentlyAcceptingCreditCards && additionalSetupDetails.hadStatementsReviewed && (
						<div className="col col-sml-12 col-med-6 form__field">
							<div id="statementsReviewedDba_div">
								<label className="label">DBA As Reviewed </label>
								<input
									type="text"
									className="input input--med"
									name="additionalSetupDetails_statementsReviewedDba"
									value={additionalSetupDetails.statementsReviewedDba}
									onChange={this.props.handleChange}
								></input>
							</div>
						</div>
					)}
				</div>

				<div className="separator--grey1 spc--bottom--med "></div>
				<div className="row">
					<div className="col col-sml-12 col-med-6 form__field">
						<div id="goodsOrServicesDescription_div" className="required">
							<label className="label">Goods/Services Sold </label>
							<input
								type="text"
								className="input input--med"
								name="goodsOrServicesDescription"
								value={setup.goodsOrServicesDescription}
								onChange={this.props.handleChange}
							></input>
						</div>
					</div>
				</div>
				{this.renderProcessingMethodLabel()}
				<div className="row row-align-middle">
					<div className="col col-sml-12 col-lrg-3 form__field">
						<div id="processingDetails_swipedTransactionsPercentage_div">
							<label className="label">Swiped </label>
							{this.renderNumberInput(
								setup.processingDetails.swipedTransactionsPercentage,
								'processingDetails_swipedTransactionsPercentage',
								'%',
								[0, 100]
							)}
						</div>
					</div>
					<div className="col col-sml-12 col-lrg-3 form__field">
						<div id="processingDetails_keyedTransactionsPercentage_div">
							<label className="label">Keyed </label>
							{this.renderNumberInput(
								setup.processingDetails.keyedTransactionsPercentage,
								'processingDetails_keyedTransactionsPercentage',
								'%',
								[0, 100]
							)}
						</div>
					</div>
					<div className="col col-sml-12 col-lrg-3 form__field">
						<div id="processingDetails_internetTransactionsPercentage_div">
							<label className="label">Internet </label>
							{this.renderNumberInput(
								setup.processingDetails.internetTransactionsPercentage,
								'processingDetails_internetTransactionsPercentage',
								'%',
								[0, 100]
							)}
						</div>
					</div>
					<div className="col col-sml-12 col-lrg-3 form__field">
						<div className="form_field">
							<label className="label">Total </label>
							<div
								name="totalProcessing"
								className={total === 100 ? 'form__total form__total--success' : 'form__total form__total--warning'}
							>
								{total}
							</div>
						</div>
					</div>
				</div>
				<div className="card--primary__subtitle">V/M Transaction Information:</div>
				<div className="row row-align-bottom">
					<div className="col col-sml-12 col-lrg-4">
						<div id="processingDetails_yearlyVmcVolume_div" className="form__field spc--bottom--med required">
							<label className="label">V/M Average Annual Volume </label>
							{this.renderNumberInput(
								setup.processingDetails.yearlyVmcVolume,
								'processingDetails_yearlyVmcVolume',
								'$0'
							)}
						</div>
					</div>
					<div className="col col-sml-12 col-lrg-4">
						<div id="processingDetails_averageTransactionAmount_div" className="form__field spc--bottom--med required">
							<label className="label">V/M Average transaction dollar amount </label>
							{this.renderNumberInput(
								setup.processingDetails.averageTransactionAmount,
								'processingDetails_averageTransactionAmount',
								'$0'
							)}
						</div>
					</div>
					<div className="col col-sml-12 col-lrg-4">
						<div id="processingDetails_maxTransactionAmount_div" className="form__field spc--bottom--med">
							<label className="label">V/M highest transaction dollar amount </label>
							{this.renderNumberInput(
								setup.processingDetails.maxTransactionAmount,
								'processingDetails_maxTransactionAmount',
								'$0'
							)}
						</div>
					</div>
					{this.renderTransactionInformation()}
				</div>
			</Card>
		);
	}

	renderOptBlue() {
		const { setup } = this.props;
		return (
			<React.Fragment>
				<div className="row">
					<div className="col col-sml-12">
						{this.renderRateStructure('amexDetails', 'AMEX ', false, setup.amexDetails.pricingStructureType)}
					</div>
					<div className="col col-sml-12 col-med-6 form__field">
						<label className="label">Amex Trans/Auth Fee (if diff than Visa/MC/Disc) </label>
						{this.renderNumberInput(setup.amexDetails.transactionFee, 'amexDetails_transactionFee', '$0')}
					</div>
					<div id="amexDetails_yearlyAmexVolume_div" className="col col-sml-12 col-med-6 form__field">
						<label className="label">Opt-Blue Average Annual Volume </label>
						{this.renderNumberInput(setup.amexDetails.yearlyAmexVolume, 'amexDetails_yearlyAmexVolume', '$0')}
					</div>
				</div>
			</React.Fragment>
		);
	}

	renderAmex() {
		const { setup } = this.props;
		return (
			<React.Fragment>
				<DropdownContainer
					labelClass="label"
					selectClass="input input--med input--select"
					wrapperClass="col col-sml-12 col-med-6 spc--bottom--med"
					labelText="Amex Entitlement"
					divClass="row flex--align--bottom"
					selectName="amexDetails_status"
					value={setup.amexDetails.status}
					onChange={this.props.handleChange}
					options={[
						{
							value: 'New',
							text: 'New AMEX',
							children: this.renderESA(true),
						},
						{
							value: 'Existing',
							text: 'Existing AMEX',
							children: this.renderESA(false),
						},
					]}
				></DropdownContainer>
				{this.renderTransactionFee(setup)}
			</React.Fragment>
		);
	}

	renderTransactionFee(setup) {
		return (
			<div className="row">
				<div className="col col-sml-12 col-med-6 form__field spc--bottom--med">
					<label className="label">Amex Trans/Auth Fee (if diff than Visa/MC/Disc) </label>
					{this.renderNumberInput(setup.amexDetails.transactionFee, 'amexDetails_transactionFee', '$0')}
				</div>
			</div>
		);
	}

	renderESA(isNew) {
		const { setup } = this.props;
		if (isNew) {
			return (
				<React.Fragment>
					<div className="col col-sml-12 col-med-4 spc--bottom--med">
						<label className="label">ESA Annual Volume</label>
						{this.renderNumberInput(setup.amexDetails.esaAnnualVolume, 'amexDetails_esaAnnualVolume', '$')}
					</div>
					<div className="col col-sml-12 col-med-4 spc--bottom--med">
						<label className="label">ESA Average Transaction Dollar Amount</label>
						{this.renderNumberInput(setup.amexDetails.esaAverageTicket, 'amexDetails_esaAverageTicket', '$')}
					</div>
					<div className="col col-sml-12 col-med-4 spc--bottom--med">
						<label className="label">ESA Highest Transaction Dollar Amount</label>
						{this.renderNumberInput(setup.amexDetails.esaHighTicket, 'amexDetails_esaHighTicket', '$')}
					</div>
					<div className="col col-sml-12 col-med-4 spc--bottom--med flex--align--self--center">
						<input
							className="input--check"
							type="checkbox"
							id="amexDetails_hasReversePip"
							name="amexDetails_hasReversePip"
							value={setup.amexDetails.hasReversePip}
							checked={setup.amexDetails.hasReversePip}
							onChange={this.props.handleChange}
						/>
						<label htmlFor="amexDetails_hasReversePip" className="label">
							Set Up Merchant with Pip
						</label>
					</div>
				</React.Fragment>
			);
		}
		return (
			<div className="col col-sml-12 col-med-6 spc--bottom--med">
				<NumberFormat
					className="input input--med"
					name="amexDetails_esaNumber"
					value={setup.amexDetails.esaNumber}
					placeholder="Amex ESA number"
					thousandSeparator={false}
					fixedDecimalScale={false}
					decimalScale={0}
					allowNegative={false}
					onValueChange={({ formattedValue }) =>
						this.props.handleChange({
							target: {
								value: formattedValue,
								name: 'amexDetails_esaNumber',
							},
						})
					}
				/>
			</div>
		);
	}

	renderRateStructure(rateObjectName, labelPrefix, readonly, defaultValue, tooltip) {
		const { setup } = this.props;
		const namePrefix = rateObjectName + '_';
		// temporary code to allow ui to work without api
		if (!setup[rateObjectName]) setup[rateObjectName] = {};

		let rateObj = setup[rateObjectName];
		return (
			<React.Fragment>
				<DropdownContainer
					labelText={labelPrefix + 'Rate Structure'}
					labelClass="label"
					selectClass="input input--med input--select"
					wrapperClass="col col-sml-12 col-med-6 form__field spc--bottom--med"
					divClass="row"
					selectName={namePrefix + 'pricingStructureType'}
					required={true}
					value={defaultValue}
					onChange={this.handleChangeWithUpdate}
					disabled={readonly}
					tooltip={tooltip}
					options={[
						{ value: 'Unknown', text: 'Please Select', default: true },
						{
							value: 'PassThrough',
							text: 'Pass Through',
							children: (
								<div className="col col-sml-12 col-med-6">
									<div className="spc--bottom--med">
										<label className="label">{labelPrefix} Pass Through </label>
										{this.renderNumberInput(
											rateObj.passThroughPercentage,
											namePrefix + 'passThroughPercentage',
											'Pass Through Rate'
										)}
									</div>
								</div>
							),
						},
						{
							value: 'FlatRate',
							text: 'Flat Rate',
							children: (
								<div className="col col-sml-12 col-med-6">
									<div className="spc--bottom--med">
										<label className="label">{labelPrefix} Flat Rate </label>
										{this.renderNumberInput(rateObj.flatRatePercentage, namePrefix + 'flatRatePercentage', 'Flat Rate')}
									</div>
								</div>
							),
						},
						{
							value: 'Tiered',
							text: 'Tiered',
							children: (
								<div className="col col-sml-12">
									<div className="row">
										<div className="col col-sml-12 col-lrg-3">
											<div className="spc--bottom--med form__field">
												<label className="label">Qual </label>
												{this.renderNumberInput(
													rateObj.tieredRatePercentages.qualified,
													namePrefix + 'tieredRatePercentages_qualified',
													'%',
													[0, 100]
												)}
											</div>
										</div>
										<div className="col col-sml-12 col-lrg-3">
											<div className="spc--bottom--med form__field">
												<label className="label">Mid-Qual </label>
												{this.renderNumberInput(
													rateObj.tieredRatePercentages.midQualified,
													namePrefix + 'tieredRatePercentages_midQualified',
													'%',
													[0, 100]
												)}
											</div>
										</div>
										{this.renderNonQual(rateObj, namePrefix)}
										{namePrefix.indexOf('amex') === 0 ? null : (
											<div className="col col-sml-12 col-lrg-3">
												<div className="spc--bottom--med">
													<label className="label">Rewards </label>
													{this.renderNumberInput(
														rateObj.tieredRatePercentages.rewards,
														namePrefix + 'tieredRatePercentages_rewards',
														'%',
														[0, 100]
													)}
												</div>
											</div>
										)}
									</div>
								</div>
							),
						},
						{
							value: 'BillBack',
							text: 'Bill Back',
							children: (
								<React.Fragment>
									<div className="col col-sml-12 col-med-6">
										<div className="form__field spc--bottom--med">
											<label className="label">{labelPrefix} Base Rate </label>
											{this.renderNumberInput(
												rateObj.billBackRatePercentages.base,
												namePrefix + 'billBackRatePercentages_base',
												'Base Rate'
											)}
										</div>
									</div>
									<div className="col col-sml-12 col-med-6">
										{this.renderEnhancedRate(rateObj.billBackRatePercentages.enhanced, labelPrefix, namePrefix)}
									</div>
								</React.Fragment>
							),
						},
					]}
				></DropdownContainer>
			</React.Fragment>
		);
	}

	renderEnhancedRate(value, labelPrefix, namePrefix) {
		return namePrefix.indexOf('amex') === 0 ? null : (
			<div className="form__field spc--bottom--med">
				<label className="label">{labelPrefix} Enhanced Rate </label>
				{this.renderNumberInput(value, namePrefix + 'billBackRatePercentages_enhanced', 'Enhanced Rate')}
			</div>
		);
	}

	renderAmountInput(value, name) {
		return this.renderNumberInput(value, name, '$0', [], '$');
	}

	renderNumberInput(value, name, placeholder, allowedValues = [], prefix = '') {
		const componentProps = {
			id: name,
			value: value,
			className: 'input input--med',
			placeholder: placeholder,
			thousandSeparator: true,
			decimalScale: 2,
			onValueChange: ({ floatValue }) => this.props.handleChange({ target: { name, value: floatValue } }),
			prefix,
		};

		if (!isEmpty(allowedValues)) {
			componentProps.isAllowed = this.checkIfAllowedValue(...allowedValues);
		}
		return <ZeroAsEmptyNumberFormat {...componentProps} />;
	}

	renderNonQual(rateObj, namePrefix) {
		return (
			<div className="col col-sml-12 col-lrg-3">
				<div className="spc--bottom--med form__field">
					<label className="label">Non-Qual </label>
					{this.renderNumberInput(
						rateObj.tieredRatePercentages.nonQualified,
						namePrefix + 'tieredRatePercentages_nonQualified',
						'%',
						[0, 100]
					)}
				</div>
			</div>
		);
	}

	handleChangeWithUpdate = e => {
		this.props.handleChange(e);
	};

	renderTransactionInformation() {
		return null;
	}

	renderProcessingMethodLabel() {
		return (
			<div className="required">
				<label className="card--primary__subtitle">Processing method (Must equal 100%)</label>
			</div>
		);
	}
}

USSetupForm.propTypes = {
	setup: PropTypes.object,
	handleChange: PropTypes.func,
};

class Card extends Component {
	getToggledClassName = isToggled => (isToggled ? ' is-expanded' : ' is-collapsed');
	getIconClassName = isToggled => (isToggled ? ' rotate--180' : '');
	getHiddenClassName = isToggled => (isToggled ? '' : ' display--n');

	render() {
		const { title, showSeparator = true, children, tooltip } = this.props;
		return (
			<Toggle initialToggle={true}>
				{({ isToggled, handleToggle }) => (
					<div className="card--primary card--sml spc--bottom--med">
						<div
							className={`card--primary__header${
								showSeparator ? '' : ' card--primary__header--no-separator'
							}${this.getToggledClassName(isToggled)}`}
						>
							<div className="flex--primary">
								<h6 className="spc--bottom--sml">{title}</h6>
								{tooltip && (
									<i
										class="icon icon--tiny icon--info spc--left--tny spc--bottom--sml datatooltip--right datatooltip--150"
										data-tooltip={tooltip}
									></i>
								)}
							</div>
							<div className="card--primary__header__expand" onClick={handleToggle}>
								<i
									className={`icon icon--arrow icon--tiny icon--arrow--down--primary ${this.getIconClassName(
										isToggled
									)}`}
								></i>
							</div>
						</div>
						<div className={`details__content ${this.getHiddenClassName(isToggled)}`}>{children}</div>
					</div>
				)}
			</Toggle>
		);
	}
}
