import React, { Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import AchBaseClass from './AchBaseClass';
import Notification from '../../common/components/notifications/notifications';
import { appService } from '../../services/appService';
import { ModalWrapper, modalNames } from '../../common/components/modal-wrapper';
import withLoader from '../../common/components/loader/loader-hoc';
import withBlock from '../../common/components/block/block-hoc';
import { Toggle, ToggleContainer } from '../../common/components/toggle';
import AppSummary from './AppSummary';
import { defaultInvalidDates, openFile } from '../../common/utilities';
import { get, cloneDeep, map, startCase, toLower, some, noop, trim, every } from 'lodash';
import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';
import { AchInvalidTemplate, AchRequiredTemplate } from '../../validation';
import Schema from '../../validation/BaseSchema';
import { withCancelable } from '../../common/components/cancelable';
import { YesNoRadio } from '../../common/components/yes-no-radio';
import { principalService } from '../../services';

const requiredFields = [
	'businessInformation.businessPhone',
	'businessInformation.businessAddress.streetAddress',
	'businessInformation.businessAddress.city',
	'businessInformation.businessAddress.state',
	'businessInformation.businessAddress.zip',
	'businessInformation.businessEmail',
	'businessInformation.website',
	'businessInformation.ownershipType',
	'businessStartDate',
	'corporateName',
	'corporateAddress.streetAddress',
	'corporateAddress.city',
	'corporateAddress.state',
	'corporateAddress.zip',
	'signerInformation.address.streetAddress',
	'signerInformation.address.city',
	'signerInformation.address.state',
	'signerInformation.address.zip',
	'signerInformation.firstName',
	'signerInformation.lastName',
	'signerInformation.phoneNumber',
	'signerInformation.socialSecurityNumber',
	'signerInformation.dateOfBirth',
	'signerInformation.ownershipPercentage',
	'signerInformation.email',
	'signerInformation.title',
	'IdentificationNumber',
	'contactPhoneNumber',
	'businessContactName',
	'IdentificationType',
	'IdentificationIssueDate',
	'IdentificationExpirationDate',
	'dba',
	'goodsOrServicesDescription',
	'taxID',
	'bankAccountInformation.bankName',
	'bankAccountInformation.accountNumber',
	'bankAccountInformation.routingNumber',
	'generalTransactionInfo.maxSingle',
	'generalTransactionInfo.maxDaily',
	'generalTransactionInfo.maxDailyAmount',
	'generalTransactionInfo.maxPeriod',
	'generalTransactionInfo.maxPeriodAmount',
	'generalTransactionInfo.averageTransactionAmount',
];

const conditionalRequiredFields = {
	enableSecondaryBankAccountInfo: {
		condition: state => state['enableSecondaryBankAccountInfo'],
		fields: [
			'bankAccountInformation.secondaryBankName',
			'bankAccountInformation.secondaryAccountNumber',
			'bankAccountInformation.secondaryRoutingNumber',
		],
	},
	alternateBankAccountInfo: {
		condition: state => state['enableAlternativeBankAccountInfo'],
		fields: [
			'bankAccountInformation.alternateBankName',
			'bankAccountInformation.alternateAccountNumber',
			'bankAccountInformation.alternateRoutingNumber',
		],
	},
};

const requiredDocs = ['BankStatements', 'ProofOfAddress', 'VoidedCheck', 'DriversLicense', 'SignedMPA'];

class AchEappFormComponent extends AchBaseClass {
	constructor(props) {
		super(props, requiredFields, requiredDocs, conditionalRequiredFields);
		this.notificationRef = createRef();
		this.mpaRequiredSchema = new Schema(cloneDeep(AchRequiredTemplate), { strip: false, typecast: true });
		this.mpaInvalidSchema = new Schema(cloneDeep(AchInvalidTemplate), { strip: false, typecast: true });
		this.state = cloneDeep(this.initialState);
	}

	validateField = () => '';

	scrollToTop = () => {
		const topElement = document.querySelector('#topOfPage');

		if (topElement) {
			topElement.scrollTop = 0;
		}
	};

	componentDidMount() {
		this.fetchAndSetData();
		this.scrollToTop();
	}

	fetchData = async () => {
		const { appid } = this.props.match ? this.props.match.params : '';
		return appService.getEApp(appid);
	};

	fetchAndSetData = async () => {
		const { appid } = this.props.match ? this.props.match.params : '';
		const { showLoader, makePendingRequest } = this.props;
		try {
			showLoader(true);
			const mpa = await makePendingRequest(
				this.fetchData()
					.then(data => data)
					.catch(err => {
						this.setState({ errorMessage: 'An error occurred: ' + err, isNewError: true });
					}),
				'getEApp'
			);
			const secondaryBankInfo = [
				mpa.bankAccountInformation.secondaryBankName,
				mpa.bankAccountInformation.secondaryAccountNumber,
				mpa.bankAccountInformation.secondaryRoutingNumber,
			];
			const alternateBankInfo = [
				mpa.bankAccountInformation.alternateBankName,
				mpa.bankAccountInformation.alternateAccountNumber,
				mpa.bankAccountInformation.alternateRoutingNumber,
			];
			const enableSecondaryBankAccountInfo = some(secondaryBankInfo, val => val);
			const enableAlternativeBankAccountInfo = some(alternateBankInfo, val => val);
			const sameAsBusiness = every(mpa.corporateAddress, (value, key) => {
				return (
					toLower(value) === toLower(mpa.businessInformation.businessAddress[key]) && value !== null && value !== ''
				);
			});
			this.setState(
				{
					enableSecondaryBankAccountInfo,
					enableAlternativeBankAccountInfo,
					sameAsBusiness,
					fields: defaultInvalidDates(mpa, ''),
					appId: appid,
				},
				this.createFormSnapshot
			);
			showLoader(false);
		} catch (err) {
			showLoader(false);
		}
	};

	save = async goToNextStep => {
		if (this.state.disableSave) {
			return;
		}
		this.setState({ disableSave: true });
		const { history } = this.props;
		const mpa = cloneDeep(this.state.fields);
		this.props.showLoader(true);
		if (this.isDirty) {
			appService
				.saveAchEApp(mpa)
				.then(() => {
					this.saveFiles().then(() => {
						this.props.showLoader(false);
						this.setState({ localFiles: {}, disableSave: false, errorMessage: '' }, async () => {
							await this.props.handleBlockChange(false);
							if (!goToNextStep) {
								return await this.fetchAndSetData();
							}
							history.push('/eapp/ach-fees/' + this.state.appId);
						});
					});
				})
				.catch(err => {
					this.handleError(err);
				});
		} else {
			this.props.showLoader(false);
			this.setState({ disableSave: false }, async () => {
				await this.props.handleBlockChange(false);
				if (!goToNextStep) return;
				history.push('/eapp/ach-fees/' + this.state.appId);
			});
		}
	};

	renderToggleableSection(title, component, tooltip = null) {
		return (
			<Toggle initialToggle={true}>
				{({ isToggled, handleToggle }) => {
					return (
						<Fragment>
							<div className={`card--primary__header ${isToggled ? 'is-expanded' : 'is-collapsed'}`}>
								<div className="flex--primary">
									<h6 className="spc--bottom--sml">{title}</h6>
									{tooltip && (
										<i
											className="icon icon--tiny icon--info spc--left--tny spc--bottom--sml datatooltip--right datatooltip--150"
											data-tooltip={tooltip}
										></i>
									)}
								</div>
								<div
									className="card--primary__header__expand"
									onKeyDown={e => this.onEnterKeyDownHandler(e, handleToggle)}
									onClick={handleToggle}
								>
									<i
										className={`icon icon--arrow icon--tiny icon--arrow--down--primary ${
											isToggled ? 'rotate--180' : ''
										}`}
									></i>
								</div>
							</div>
							{isToggled && component}
						</Fragment>
					);
				}}
			</Toggle>
		);
	}

	renderValidationInfo() {
		const { fields, disableSave, enableAlternativeBankAccountInfo, enableSecondaryBankAccountInfo } = this.state;
		const requiredErrorList = this.mpaRequiredSchema.validate(
			Object.assign({}, { ...fields, enableAlternativeBankAccountInfo, enableSecondaryBankAccountInfo })
		);
		const invalidErrorList = this.mpaInvalidSchema.validate(Object.assign({}, fields));
		if (invalidErrorList.length > 0) {
			if (!disableSave) this.setState({ disableSave: true });
		} else {
			if (disableSave) this.setState({ disableSave: false });
		}
		const requiredErrorListPaths = requiredErrorList.map(error => error.path);
		const invalidErrorListPaths = invalidErrorList.map(error => error.path);
		const incompleteDocs = this.validateDocuments();

		if (
			this.props.isLoading ||
			!(incompleteDocs.length || requiredErrorListPaths.length || invalidErrorListPaths.length)
		)
			return null;
		return (
			<React.Fragment>
				{invalidErrorListPaths.length > 0 && (
					<div className="note note--warning spc--bottom--med">
						<ul>
							{map(invalidErrorList, (elem, i) => (
								<li key={i}>
									<div
										className="anchor"
										onKeyDown={e => this.onEnterKeyDownHandler(e, () => this.onErrorClick(elem))}
										onClick={() => this.onErrorClick(elem)}
									>
										<i className="icon icon--nano icon--text-top icon--alert spc--right--tny"></i>{' '}
										{defaultReactOutput(defaultImplicitParse(elem.message))}
									</div>
								</li>
							))}
						</ul>
					</div>
				)}
				{(incompleteDocs.length > 0 || requiredErrorListPaths.length > 0) && (
					<div>
						<div className="note note--uncompleted spc--bottom--med">
							<div className="separator--dark spc--bottom--tny">
								<div className="flex flex--primary">
									<i className="icon icon--sml icon--text-top icon--alert-circle spc--right--tny spc--bottom--tny"></i>
									<p className="type--color--text--dark spc--bottom--tny">
										The fields below are required for submitting the app; however, you can save the form without this
										information now and complete the form at a later time.
									</p>
								</div>
							</div>

							<ul className="list list--primary list--primary--dark">
								{map(requiredErrorList, (elem, i) => (
									<li className="item" key={i}>
										<div
											className="anchor"
											onKeyDown={e => this.onEnterKeyDownHandler(e, () => this.onErrorClick(elem))}
											onClick={() => this.onErrorClick(elem)}
										>
											{defaultReactOutput(defaultImplicitParse(elem.message))}
										</div>
									</li>
								))}
								{map(incompleteDocs, (fileTag, index) => (
									<li className="item" key={`${fileTag}.${index}`}>
										<a
											className="anchor"
											onKeyDown={e =>
												this.onEnterKeyDownHandler(e, () =>
													this.scrollTo(toLower(fileTag).replace(/[.]/g, '_') + '_div')
												)
											}
											onClick={() => this.scrollTo(toLower(fileTag).replace(/[.]/g, '_') + '_div')}
										>
											<span className="type--wgt--bold">
												{' '}
												{fileTag == 'SignedMPA' ? 'Signed ACH Worksheet' : startCase(fileTag)} document
											</span>
										</a>
									</li>
								))}
							</ul>
						</div>
					</div>
				)}
			</React.Fragment>
		);
	}

	renderFooter() {
		const { disableSave } = this.state;
		const { isLoading } = this.props;
		return (
			<div>
				{this.renderValidationInfo()}
				<div className="type--right">
					<button
						className="btn btn--primary btn--med spc--right--tny"
						disabled={disableSave || isLoading}
						onClick={() => this.save(false)}
					>
						Save
					</button>
					<button
						className="btn btn--primary btn--med"
						disabled={disableSave || isLoading}
						onClick={() => this.save(true)}
					>
						Save and Next Step
					</button>
				</div>
			</div>
		);
	}

	renderUploadDocuments() {
		const {
			fields: { files },
		} = this.state;
		return (
			<Fragment>
				{files && this.getFilesForTag('SignedMPA').length > 0 ? (
					<div>
						<div className="spc--bottom--sml">
							<strong>Signature Documents</strong>
						</div>
						<div className="card--no-expand">{this.renderFilesForTag(['SignedMPA'])}</div>
					</div>
				) : null}
				<div className={`details__content`}>
					{this.renderUploadDocumentRow(
						'BankStatements',
						'Bank Statements',
						'Please include your three most recent bank statements',
						true,
						'bankstatements_div'
					)}
					{this.renderUploadDocumentRow('VoidedCheck', 'Voided Check', 'Or Bank Letter', true, 'voidedcheck_div')}
					{this.renderUploadDocumentRow(
						'ProofOfAddress',
						'Proof of Address',
						'Utility bill or Lease Agreement',
						true,
						'proofofaddress_div'
					)}
					{this.renderUploadDocumentRow('DriversLicense', 'Drivers License', null, true, 'driverslicense_div')}
					{this.renderUploadDocumentRow('TaxExemption', 'Tax Exempt Docs', null, true, 'taxexemption_div')}
					{this.renderUploadDocumentRow('CorporateDocs', 'Corp Docs', null, true, 'corporatedocs_div')}
					{this.renderUploadDocumentRow(
						'MerchantStatement',
						'Recent Processing Statement',
						null,
						true,
						'merchantstatement_div'
					)}
					{this.renderUploadDocumentRow(
						'CompanyLogo',
						'Marketing',
						'Flyer, Brochure or Company Website',
						true,
						'companylogo_div'
					)}
				</div>
			</Fragment>
		);
	}
	renderRep2Field = (telemarketerList, telemarketerEmail) => {
		const isCardknox = telemarketerList?.length > 0;
		const telemarketerLabel = isCardknox ? 'Rep 2' : 'Rep 2 Email';
		return (
			<div className="col col-sml-12 col-med-6 spc--bottom--med">
				<label className="label">
					{telemarketerLabel}
					{isCardknox && (
						<span data-tooltip="Required" className="type--color--primary">
							{' '}
							*
						</span>
					)}
				</label>
				{isCardknox ? (
					<select
						placeholder="Telemarketer"
						className="input input--med input--select"
						name="telemarketers"
						value={get(
							telemarketerList.find(x => x.agentName === telemarketerEmail),
							'agentName',
							''
						)}
						onChange={this.handleTelemarketerChange}
					>
						<option value="">Please select...</option>
						{telemarketerList.map(telemarketer => (
							<option key={telemarketer.agentId}>{telemarketer.agentName}</option>
						))}
					</select>
				) : (
					<input
						type="text"
						placeholder="Rep 2 Email"
						className="input input--med"
						name="telemarketerEmail"
						value={trim(telemarketerEmail)}
						onChange={this.handleChange}
					></input>
				)}
			</div>
		);
	};

	renderHasExistingFidelityAccount = (hasExistingFidelityAccount, existingAccountDba) => {
		return (
			<div className="required col col-sml-12 col-med-6">
				<label className="label">The signer or business has, or has previously had, an account with Fidelity</label>
				<YesNoRadio
					name="hasExistingFidelityAccount"
					yes={hasExistingFidelityAccount}
					onChange={this.handleYesNoChange}
				/>
				{hasExistingFidelityAccount && (
					<div className="row spc--bottom--med">
						<div className="col col-sml-12 col-med-6">
							<label className="label">DBA/MID </label>
							<input
								type="text"
								placeholder="DBA/MID of existing account"
								className="input input--med"
								name="existingAccountDba"
								value={existingAccountDba}
								onChange={this.handleChange}
							></input>
						</div>
					</div>
				)}
			</div>
		);
	};
	renderAdditionalTransactionInformation() {
		const {
			fields: {
				generalTransactionInfo: {
					maxSingle,
					maxDaily,
					maxDailyAmount,
					maxPeriod,
					maxPeriodAmount,
					averageTransactionAmount,
				},
			},
		} = this.state;
		return (
			<div className="row">
				{this.renderNumberFormat({ name: 'generalTransactionInfo.maxSingle', value: maxSingle, label: 'Max Single' })}
				{this.renderNumberFormat({
					name: 'generalTransactionInfo.maxDaily',
					value: maxDaily,
					label: 'Daily Check Amount',
				})}
				{this.renderNumberFormat({
					name: 'generalTransactionInfo.maxDailyAmount',
					value: maxDailyAmount,
					label: 'Max Daily Volume',
				})}
				{this.renderNumberFormat({
					name: 'generalTransactionInfo.maxPeriod',
					value: maxPeriod,
					label: 'Monthly Check Amount',
				})}
				{this.renderNumberFormat({
					name: 'generalTransactionInfo.maxPeriodAmount',
					value: maxPeriodAmount,
					label: 'Max Monthly Volume',
				})}
				{this.renderNumberFormat({
					name: 'generalTransactionInfo.averageTransactionAmount',
					value: averageTransactionAmount,
					label: 'Expected Average Amount',
					decimalScale: 4,
				})}
			</div>
		);
	}

	tabGoTo = path => {
		const { appid } = this.props.match ? this.props.match.params : '';
		if (appid) this.props.history.push(`/eapp/${path}/${appid}`);
	};

	renderTabs() {
		return (
			<React.Fragment>
				<ul className="tabs--primary spc--bottom--lrg">
					<li className="tabs--primary__item is-active">MPA</li>
					<li
						className="tabs--primary__item disabled"
						onKeyDown={e => this.onKeyDownHandler(e, () => this.tabGoTo('ach-fees'))}
						onClick={() => this.tabGoTo('ach-fees')}
					>
						Fees
					</li>
					<li
						className="tabs--primary__item"
						onKeyDown={e => this.onKeyDownHandler(e, () => this.tabGoTo('ach-confirm'))}
						onClick={() => this.tabGoTo('ach-confirm')}
					>
						Confirm
					</li>
				</ul>
			</React.Fragment>
		);
	}

	sendSharedFormEmail = () => {
		const {
			fields: {
				ownerName,
				businessContactName,
				dba,
				businessInformation: { businessEmail },
			},
			appId,
		} = this.state;
		this.openCloseModal({
			name: modalNames.shareMPA,
			data: {
				dba: dba,
				appid: appId,
				fieldOptions: this.state.fields.fieldOptionsRequiredForMerchant,
				documents: ['ProfitStarsConfirmation'],
				merchantEmail: businessEmail,
				merchantName: ownerName || businessContactName || dba,
			},
		});
	};

	saveAndEmailApplication = () => {
		if (this.state.disableSave) {
			return;
		}
		this.setState({ disableSave: true });
		const mpa = cloneDeep(this.state.fields);
		if (this.isDirty) {
			this.props.showLoader(true);
			appService
				.saveEApp(mpa)
				.then(() => {
					this.props.showLoader(false);
					this.setState({ disableSave: false }, () => {
						this.props.handleBlockChange(false);
					});
					this.sendSharedFormEmail();
				})
				.catch(err => {
					this.handleError(err);
					this.setState({ disableSave: false });
				});
		} else {
			this.setState({ disableSave: false });
			this.sendSharedFormEmail();
		}
	};

	showUploadMPA = () => {
		this.openCloseModal({
			name: modalNames.fileUpload,
			data: {
				tagList: ['SignedMPA'],
				handleSave: this.saveMPAFile,
				closeModal: this.openCloseModal,
			},
		});
	};

	saveMPAFile = (fileTags, files) => {
		if (!fileTags || !files || !files[fileTags]) {
			this.openCloseModal({
				name: modalNames.none,
			});
			return;
		}
		return appService
			.saveMerchantFiles(this.state.appId, files)
			.then(() => {
				const { fields, disableSave } = this.state;
				if (this.isDirty && !disableSave) {
					return appService
						.saveAchEApp(fields)
						.then(this.saveFiles)
						.then(() => {
							return new Promise(resolve => {
								this.setState({ localFiles: {}, disableSave: false }, () => {
									this.props
										.handleBlockChange(false)
										.then(resolve)
										.then(this.fetchAndSetData());
								});
							});
						})
						.catch(err => {
							this.handleError(err);
						});
				} else if (!disableSave) {
					return this.fetchAndSetData();
				}
			})
			.then(() => {
				const addNotification = get(this.notificationRef, 'current.addNotification', noop);
				this.openCloseModal({
					name: modalNames.none,
				});
				addNotification({
					message: 'Successfully uploaded',
					success: true,
				});
			})
			.catch(err => {
				console.log('save files error', err);
				this.props.showLoader(false);
				this.setState({ errorMessage: 'Files could not be uploaded - an error occurred: ' + err, isNewError: true });
			});
	};

	renderUploadSignedMpa() {
		const { isLoading } = this.props;
		return (
			<button
				id="uploadsignedmpa"
				disabled={isLoading}
				className="btn btn--med btn--ghost spc--bottom--sml"
				onClick={this.showUploadMPA}
			>
				Upload Signed ACH Worksheet
			</button>
		);
	}

	renderSendFormToMerchant() {
		const { disableSave } = this.state;
		const { isLoading } = this.props;

		return (
			<button
				className="btn btn--med btn--primary spc--right--sml spc--bottom--sml datatooltip--200"
				data-tooltip="Sending the MPA through Partner Portal is the fastest way to get it to the lead."
				disabled={disableSave || isLoading}
				onClick={this.saveAndEmailApplication}
			>
				Send Form to Merchant
			</button>
		);
	}

	handleMPADownload = forPrint => e => {
		this.props.showLoader(true);
		let agent = principalService.get();
		let agentName = agent ? agent.name : '';
		const data = {
			...this.state.fields,
			agentName,
			enableAlternativeBankAccountInfo: this.state.enableAlternativeBankAccountInfo,
			enableSecondaryBankAccountInfo: this.state.enableSecondaryBankAccountInfo,
		};
		data.signerInformation.ownershipPercentage = data.signerInformation.ownershipPercentage.toString();
		appService
			.prefillDocumentFields(data, 'ACH_application_worksheet', this.achWorksheetFieldNames, false)
			.then(blob => {
				const url = URL.createObjectURL(blob);
				openFile(url, 'fileName', forPrint, forPrint);
				this.props.showLoader(false);
			});
	};

	renderToolbar() {
		const { isLoading } = this.props;
		return (
			<Fragment>
				<button
					className="btn btn--med btn--ghost spc--right--sml spc--bottom--sml datatooltip--200"
					data-tooltip="Download a prefilled MPA"
					disabled={isLoading}
					onClick={this.handleMPADownload(false)}
				>
					<i className="icon icon--xsml icon--download align--v--middle" />
				</button>
				<button
					className="btn btn--med btn--ghost spc--right--sml spc--bottom--sml datatooltip--150"
					disabled={isLoading}
					data-tooltip="Print a prefilled MPA"
					onClick={this.handleMPADownload(true)}
				>
					<i className="icon icon--xsml icon--print align--v--middle" />
				</button>
				{this.renderSendFormToMerchant()}
				{this.renderUploadSignedMpa()}
			</Fragment>
		);
	}

	render() {
		const { fields, modal } = this.state;
		const { isLoading } = this.props;

		return (
			<div id="main-div" className="l--content l--content--med">
				{this.renderTabs()}
				{this.renderErrors()}
				<Notification ref={this.notificationRef} />
				<ModalWrapper modal={modal} onModalClose={this.openCloseModal} />
				<fieldset disabled={isLoading}>
					<div className="align--h--center l--content--med">
						<div className="flex--tertiary flex--align--bottom">
							<AppSummary app={fields} isEappWebForm={false} showLeadDetailsLink={true} />
						</div>
						<div className="header header--mpa" style={{ justifyContent: 'flex-end' }}>
							{this.renderToolbar()}
						</div>
						<div>
							<ToggleContainer>
								<div className="card--primary card--sml spc--bottom--med">
									{this.renderToggleableSection('Business Information', this.renderBusinessInfo())}
								</div>
								<div className="card--primary card--sml spc--bottom--med">
									{this.renderToggleableSection('Signer Information', this.renderSignerInformation())}
								</div>
								<div className="card--primary card--sml spc--bottom--med">
									{this.renderToggleableSection('Banking Information', this.renderBankingInfo())}
								</div>
								<div className="card--primary card--sml spc--bottom--med">
									{this.renderToggleableSection('Mailing Address', this.renderMailingInfo())}
								</div>
								<div className="card--primary card--sml spc--bottom--med">
									{this.renderToggleableSection(
										'Additional Transaction Details',
										this.renderAdditionalTransactionInformation()
									)}
								</div>
								<div className="card--primary card--sml spc--bottom--med">
									{this.renderToggleableSection(
										'Upload documents',
										this.renderUploadDocuments(),
										'This will be visible to merchants'
									)}
								</div>
								<div className="card--primary card--sml spc--bottom--med">
									{this.renderToggleableSection(
										'Other Notes',
										this.renderOtherNotes(),
										'This will be visible to merchants'
									)}
								</div>
							</ToggleContainer>
							{this.renderFooter()}
						</div>
					</div>
				</fieldset>
			</div>
		);
	}
}

AchEappFormComponent.propTypes = {
	match: PropTypes.object,
	history: PropTypes.object,
	handleBlockChange: PropTypes.func,
	showLoader: PropTypes.func,
	isLoading: PropTypes.func,
};

export default withBlock(withLoader(withCancelable(AchEappFormComponent)));
