import React from 'react';
import { get, noop, toLower, isEmpty, map, startCase, some, find } from 'lodash';
import { bool, func, object } from 'prop-types';

import { appService } from '../../services/appService';
import Notification from '../../common/components/notifications/notifications';
import withLoader from '../../common/components/loader/loader-hoc';
import { goPlusEappFormTemplate, Schema, goPlusAchqEappFormTemplate } from '../../validation';
import { modalNames } from '../../common/components/modal-wrapper/modal-names';
import { ModalWrapper } from '../../common/components/modal-wrapper';
import GoPlusBaseClass from './GoPlusBaseClass';
import { defaultInvalidDates } from '../../common/utilities';
import withBlock from '../../common/components/block/block-hoc';
class GoPlusEappFormComponent extends GoPlusBaseClass {
	constructor(props) {
		super(false, props);

		this.formSchema = new Schema(goPlusEappFormTemplate, { strip: false, typecast: true });

		const initialState = this.initialState;

		this.state = {
			...initialState,
			tiers: { length: 0 },
			fields: {
				...initialState.fields,
				tierName: '',
			},
			modal: {
				name: modalNames.none,
				data: null,
			},
		};
	}

	async componentDidMount() {
		await this.setUnderwritingLevels();
		this.fetchData();
	}

	fetchData = async () => {
		const { appid: leadid } = this.props.match ? this.props.match.params : '';
		const { showLoader } = this.props;
		let { tiers, fields } = this.state;
		try {
			showLoader(true);
			const app = await appService.getGoPlus(leadid);
			this.mapGoAppToState(defaultInvalidDates(app, ''));

			const isAchqOnly = this.isAchqOnly(app?.goPlusEApp?.processorName);
			if (tiers.length === 0) {
				tiers = await appService.getGoPlusTiers(fields.appId, isAchqOnly);
				this.mapTiersToState(tiers);

				if (this.state.isLinkedToAchq || this.state.isLinkedToGoPlus) {
					if (!this.state.processingInformation.averageTicket) this.getGoPlusTierDetails(this.state.fields.tierName);
				}
			}

			if (isAchqOnly) {
				this.formSchema = new Schema(goPlusAchqEappFormTemplate, { strip: false, typecast: true });
			} else {
				this.formSchema = new Schema(goPlusEappFormTemplate, { strip: false, typecast: true });
			}
			showLoader(false);
		} catch (err) {
			showLoader(false);
			this.setState({ errorMessage: 'An error occurred: ' + err, isNewError: true });
		}
		this.addEventListenersToAccountNumberInputs();
	};

	setIsECommerce = isECommerce => {
		this.setState({
			isECommerce,
		});
	};

	openGoPlusTierPopup = tiers => {
		this.setState({
			modal: {
				name: modalNames.goPlusTier,
				data: {
					addNotification: get(this.notificationRef, 'current.addNotification', noop),
					tiers,
					setTierName: this.setTierName,
					setTiers: this.setTiersToState,
					setIsECommerce: this.setIsECommerce,
					onConfirm: this.getGoPlusTierDetails,
					agentId: this.state.fields.agentId,
					telemarketerId: this.state.fields.telemarketerId,
					appId: this.state.appId,
					isAchqOnly: this.isAchqOnly(),
					isLinkedToAchq: this.state.isLinkedToAchq,
					isSplitAchq: this.state.isSplitAchq,
				},
			},
		});
	};
	getGoPlusTierDetails = tierName => {
		appService.getTierDetails(tierName, this.state.appId, this.isAchqOnly()).then(details => {
			this.setStateFromTier(details);
		});
	};

	saveAndEmailApplication = () => {
		const showNotification = false;

		this.save(showNotification)
			.then(result => (result ? this.sendSharedFormEmail() : {}))
			.catch(err => {
				this.setState({ errorMessage: 'An error occurred: ' + err, isNewError: true });
			});
	};

	openCloseModal = modal => this.setState({ modal });

	sendSharedFormEmail = () => {
		const {
			match: {
				params: { appid },
			},
		} = this.props;
		const {
			fields: { dbaName, corporateName, businessEmail, agentEmail },
		} = this.state;
		this.openCloseModal({
			name: modalNames.shareMPA,
			data: {
				appid,
				merchantName: dbaName || corporateName,
				merchantEmail: businessEmail || agentEmail,
				documents: ['FDGoConfirmation'],
				dba: dbaName,
			},
		});
	};

	mapTiersToState = ({ tiers }) => {
		const mappedTiers = map(tiers, ([key, industry, achEnabled], value) => ({
			value,
			label: startCase(key),
			industry,
			achEnabled: achEnabled === 'true',
		}));
		const mappedTiersDict = {};
		mappedTiers.forEach(tier => (mappedTiersDict[tier.value] = tier));
		mappedTiersDict.length = mappedTiers.length;

		const needsTierPopup = () => {
			if (mappedTiers.length <= 1) return false;
			if (this.state.fields.tierName) {
				return !mappedTiersDict[this.state.fields.tierName];
			}
			return true;
		};

		const newState = { tiers: mappedTiersDict };
		if (needsTierPopup()) {
			this.openGoPlusTierPopup(mappedTiersDict);
			return;
		}
		if (!this.state.fields.tierName && mappedTiers.length === 1) {
			newState.fields = {
				...this.state.fields,
				tierName: mappedTiers[0].value,
			};
		}
		this.setState(newState);
	};

	setTiersToState = tierList => {
		this.setState({
			tiers: tierList,
		});
	};

	getNewGoPlusTierDetails = ({ target: { value: tierName } }) => {
		this.handleChange({ target: { name: 'tierName', value: tierName, type: 'string' } });
		if (!tierName) return;

		const { tiers } = this.state;
		const tier = tiers[tierName];
		if (tier) {
			this.setIsECommerce(tier.industry === 'ecommerce');
			this.getGoPlusTierDetails(tierName);
		}
	};

	checkIfShouldDisplayAsterisk = name => {
		return some(
			[
				'processingInformation.sicCode',
				'agentName',
				'agentEmail',
				'dbaName',
				'businessEmail',
				'processingInformation.highTicket',
				'processingInformation.achReturnFee',
				'processingInformation.achUnauthorizedReturnFee',
			],
			item => item === name
		);
	};

	validateFields = (scrollToTop = false, isSubmit = false) => {
		const { fields } = this.state;
		const errorList = this.formSchema.validate(Object.assign({}, this.getFieldsForValidation));

		if (
			fields.bankingInformation.accountNumber &&
			fields.bankingInformation.confirmAccountNumber !== fields.bankingInformation.accountNumber
		) {
			errorList.push({
				message: '[**Account Numbers**](javascript:void) for primary bank must match',
				path: 'bankingInformation.confirmAccountNumber',
			});
		}

		const errorListPaths = errorList.map(e => e.path);
		const hasErrors = !isEmpty(errorList);
		const newState = { errorList, errorListPaths };

		if (scrollToTop) {
			newState.isNewError = hasErrors;
		}

		newState.isSubmit = scrollToTop || isSubmit;
		this.setState(newState);

		return hasErrors;
	};

	save = showNotification => {
		const {
			showLoader,
			match: {
				params: { appid: AppId },
			},
		} = this.props;
		const { fields, processorName, processingInformation } = this.state;
		const addNotification = get(this.notificationRef, 'current.addNotification', noop);
		const data = {
			...fields,
			processorName,
			AppId,
			processingInformation,
		};

		if (this.validateFields(true)) {
			return Promise.resolve(null);
		}

		showLoader(true);

		return appService
			.saveGoPlus(data)
			.then(({ refNum: ref, status, message }) => {
				return this.saveFiles().then(() => {
					showLoader();
					this.setState({ dirty: false });
					this.props.handleBlockChange(false);
					if (!showNotification) return true;
					addNotification({
						message: 'Saved Successfully!',
						ref,
						success: toLower(status) === 's',
					});
					return true;
				});
			})
			.catch(err => {
				showLoader();
				this.handleSubmitError(err);
			})
			.finally(() => {
				this.fetchData();
			});
	};

	setTierName = value => this.handleChange({ target: { name: 'tierName', value } });

	setStateFromTier = value => {
		const newState = this.state;
		let tierInfo = value;
		if (this.isAchqOnly()) {
			this.formSchema = new Schema(goPlusAchqEappFormTemplate, { strip: false, typecast: true });
		} else {
			this.formSchema = new Schema(goPlusEappFormTemplate, { strip: false, typecast: true });
		}
		this.mapProcessingInfoToState(tierInfo, newState, true);
		this.setState(newState);
	};

	renderShareFormButton = () => {
		const {
			fields: { dbaName, businessEmail },
		} = this.state;
		const disabled = !dbaName || !businessEmail;
		let tooltip = 'Sending the Form through Partner Portal is the fastest way to get it to the lead.';

		if (disabled) {
			tooltip = 'DBA and Business Email are required.';
		}

		return (
			<button
				className="btn btn--med btn--primary spc--bottom--sml datatooltip--bottom--right datatooltip--200"
				onClick={this.saveAndEmailApplication}
				data-tooltip={tooltip}
				disabled={disabled}
			>
				Send Form to Merchant
			</button>
		);
	};

	renderTierOptions = () =>
		Object.entries(this.state.tiers).map(([_, { label, value }]) => (
			<option key={`${value}.${label}`} value={value}>
				{label}
			</option>
		));

	renderTierName = () => {
		const {
			fields: { tierName },
			tiers,
		} = this.state;

		return tiers.length > 1 ? (
			<div className="spc--bottom--sml">
				<label htmlFor="tierName" className="label">
					Tier
				</label>
				<select
					className="input input--med input--select"
					id="tierName"
					name="tierName"
					value={tierName}
					onChange={this.getNewGoPlusTierDetails}
					disabled={this.props.isLoading}
				>
					{this.renderTierOptions()}
				</select>
			</div>
		) : (
			tierName && <div className="spc--bottom--sml type--wgt--bold type--lrg">Tier: {tierName}</div>
		);
	};

	renderTabs() {
		return (
			<React.Fragment>
				<ul className="tabs--primary spc--bottom--lrg">
					<li className="tabs--primary__item is-active" onClick={noop}>
						MPA
					</li>
					<li
						className="tabs--primary__item"
						onClick={() => this.props.history.push('/eapp/cardknox-go-equipment/' + this.props.match.params.appid)}
					>
						Equipment
					</li>
				</ul>
			</React.Fragment>
		);
	}

	render() {
		const {
			modal,
			fields: { tierName },
			tiers,
			isLinkedToAchq,
			isSplitAchq,
		} = this.state;
		const { isLoading } = this.props;
		const isAchqOnly = this.isAchqOnly();
		const tier = tierName && tiers[tierName];
		const displayTierNote = tier?.achEnabled && !isAchqOnly && !isLinkedToAchq && (isSplitAchq || appService.isDev());

		return (
			<div id="main-div" className="l--content l--content--med" ref={this.topRef}>
				{!isAchqOnly && this.renderTabs() //do we need this for achq?
				}
				{this.renderErrors()}
				<Notification ref={this.notificationRef} />
				<ModalWrapper modal={modal} onModalClose={this.openCloseModal} />
				<fieldset disabled={isLoading}>
					<div className="flex--tertiary flex--align--bottom">
						{this.renderTierName()}
						{this.renderShareFormButton()}
					</div>
					<div>
						{displayTierNote && (
							<div className="note note--flex note--default spc--bottom--sml">
								Selecting this tier will create an ACHQ account in addition to the Cardknox Go account.
							</div>
						)}
						{this.renderSalesRep()}
						{this.renderBusinessInfo()}
						{this.renderProcessingInfo(false)}
						{this.renderDocumentsSection()}
						{this.renderBankingInfo()}
						{this.renderSignerInfo()}
					</div>
				</fieldset>
				<div className="spc--top--lrg">
					<div className="clearfix push">
						<button className="btn btn--primary btn--med push" onClick={this.save}>
							Save
						</button>
					</div>
				</div>
			</div>
		);
	}
}

GoPlusEappFormComponent.propTypes = {
	isLoading: bool,
	showLoader: func.isRequired,
	match: object.isRequired,
};

export default withBlock(withLoader(GoPlusEappFormComponent));
