import { some, toLower, startsWith, size, lastIndexOf, get, first, split } from 'lodash';
import { validateEmails } from '../common/utilities';
import { principalService } from '../services';

export const min = minVal => val => Math.min(minVal, val) === minVal;
export const max = maxVal => val => Math.max(maxVal, val) === maxVal;

export const MASKED_VALUE =
	'010011010100000101010011010010110100010001011101010111100101111001010110010000010100110001010101010001010'; //identifier for masked value
const http = 'http';

const urlRegex = /^(https?:\/\/|www\.)([\w-]+\.)+\w{2,4}(:\d+)?(\/[~\w#%./-]+)?(\?[\w% /&[\];=-]+)?\b$/;
const forbiddenUrlElements = [
	'�',
	'$',
	'"',
	'@',
	',',
	'..',
	'google',
	'index',
	'*',
	'\\',
	';',
	'gmail',
	'yahoo',
	'&',
	' ',
	'+',
	"'",
	'ä',
	'å',
	'æ',
	'á',
	'é',
	'í',
	'ó',
	'ö',
	'ø',
	'õ',
	'ü',
	'ú',
	'š',
	'ž',
	'ð',
	'ý',
	'þ',
];
// Needed because of sonarcloud security hotspots
const forbiddenUrlStartElements = [`${http}://http`, 'https://http', 'www.www', 'www.http', '/http://', '.www'];

export const url = val => {
	if (!val) {
		return true;
	}

	return urlRegex.test(val) && !some(forbiddenUrlStartElements, value => startsWith(toLower(val), value));
};
export const incorrectCharacters = val => {
	if (!val) {
		return true;
	}

	return !some(forbiddenUrlElements, value => toLower(val).indexOf(value) > -1);
};
export const numberOnly = val => !(isNaN(val) && val);
export const ascii = val => !/[^\u0020-\u007f]/.test(val);
export const alphaOnly = val => !/[^A-Za-z]/.test(val);
export const maxLength = limit => value => size(value) <= limit;
export const digitLength = (min, max = min) => val => {
	if (!val) {
		return true;
	}
	const digits = val.match(/\d/g) && val.match(/\d/g).length;
	return digits >= min && digits <= max;
};
export const phoneNumber = value => {
	if (!value) {
		return true;
	}
	const validPhoneNumberFormats = [/^\(?\d{3}\)?(-|\s)?\d{3}(-|\s)?\d{4}$/, /^\d{10}$/];
	return some(validPhoneNumberFormats, format => format.test(value));
};

export const addressPOBox = value => {
	if (!value) {
		return true;
	}
	return !/^(?:post(?:al)? (?:office )?|p[. ]?o\.? )?box(?:\s\d+)?$/.test(toLower(value));
};

export const ownershipTypeCorpName = ownershipType => (val, ctx) =>
	/(\bllc\b|\binc\b)/.test(toLower(ctx.corporateName)) ? val !== ownershipType : true;

export const getValueForProperty = (ctx, path, property) => {
	const indexOfMerchantPrice = lastIndexOf(path, '.');
	if (indexOfMerchantPrice === -1) return true;
	const pathToObject = path.substring(0, indexOfMerchantPrice);
	return get(ctx, `${pathToObject}.${property}`);
};

export const minFee = (val, ctx, path) => {
	let user = principalService.get();
	if (user.isSamlLogin) return true;
	if (toLower(get(ctx, 'paymentSchedule')) === 'billagent') {
		let feeType = getValueForProperty(ctx, path, 'feeType');
		if (toLower(feeType) === 'onetimefee') {
			return true;
		}
	}
	if (val !== 0 && !val) return true;
	let minFee = getValueForProperty(ctx, path, 'minFee');
	if (minFee !== 0 && !minFee) return true;
	return val >= minFee;
};

export const maxFee = (val, ctx, path) => {
	if (toLower(get(ctx, 'paymentSchedule')) === 'billagent') {
		let feeType = getValueForProperty(ctx, path, 'feeType');
		if (toLower(feeType) === 'onetimefee') {
			return true;
		}
	}
	if (val !== 0 && !val) return true;
	const maxFee = getValueForProperty(ctx, path, 'maxFee');
	if (maxFee <= 0 || !maxFee) return true;
	return maxFee >= val;
};

export const email = (multiple = true) => val => {
	if (!val) return true;
	if (multiple) return validateEmails(val);
	let emailSplit = split(val, ',');
	return emailSplit.length === 1 ? validateEmails(first(emailSplit)) : false;
};
