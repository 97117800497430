import React, { createRef } from 'react';
import {
	split,
	some,
	toLower,
	get,
	noop,
	cloneDeep,
	isEqual,
	lastIndexOf,
	includes,
	map,
	startCase,
	filter,
	isEmpty,
	first,
	each,
	transform,
	has,
} from 'lodash';
import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';

import Schema from '../../validation/BaseSchema';
import { modalNames, ModalWrapper } from '../../common/components/modal-wrapper';
import { Notification } from '../../common/components/notifications';
import { getFilesForTag, handleFileDownload, handleRemoveFile } from '../../common/utilities/commonFileHandlingMethods';
import { openFile } from '../../common/utilities';
import { appService } from '../../services';
import AppSummary from './AppSummary';

export default function withMpa(Component, invalidTemplate, requiredTemplate, requiredFields = {}) {
	class ComponentWithMpa extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				appId: props.mpa.appId,
				mpa: props.mpa,
				dirty: false,
				errorMessage: null,
				errorList: [],
				errorListPaths: [],
				files: {},
				modal: {
					name: modalNames.none,
					data: null,
				},
				disableSave: false,
				disableDelete: false,
				requiredFields: {
					'businessInformation.businessPhone': true,
					'businessInformation.businessAddress.streetAddress': true,
					'businessInformation.businessAddress.city': true,
					'businessInformation.businessAddress.state': true,
					'businessInformation.businessAddress.zip': true,
					corporateName: true,
					'corporateAddress.streetAddress': true,
					'corporateAddress.city': true,
					'corporateAddress.state': true,
					'corporateAddress.zip': true,
					'signerInformation.address.streetAddress': true,
					'signerInformation.address.city': true,
					'signerInformation.address.state': true,
					'signerInformation.address.zip': true,
					'signerInformation.firstName': true,
					'signerInformation.lastName': true,
					'signerInformation.phoneNumber': true,
					'signerInformation.cellPhoneNumber': true,
					SocialSecurityNumber: true,
					'signerInformation.dateOfBirth': true,
					IdentificationNumber: false,
					IdentificationType: false,
					IdentificationIssueDate: false,
					IdentificationExpirationDate: false,
					dba: true,
					goodsOrServicesDescription: true,
					taxID: true,
					'bankAccountInformation.accountNumber': true,
					'bankAccountInformation.routingNumber': true,
					...requiredFields,
				},
			};

			this.notificationRef = createRef();

			this.handleFileDownload = handleFileDownload.bind(this);
			this.handleRemoveFile = handleRemoveFile.bind(this);
			this.getFilesForTag = getFilesForTag.bind(this);

			const { firstName, lastName, phoneNumber } = props.mpa.signerInformation;
			this.originalSignerInformation = {
				firstName,
				lastName,
				phoneNumber,
			};
			this.mpaRequiredSchema = new Schema(cloneDeep(requiredTemplate), { strip: false, typecast: true });
			this.mpaInvalidSchema = new Schema(cloneDeep(invalidTemplate), { strip: false, typecast: true });

			this.setRequiredFields();
		}
		get requiredDocuments() {
			return map(get(this.props.sidebarRef, 'current.state.appStatus.requiredDocuments', []), ({ fileTag }) => fileTag);
		}
		get mpaData() {
			const { mpa } = this.state;
			const mpafiles = mpa && this.getFilesForTag(['SignedMPA', 'SignaturePages']);
			return {
				mpa,
				mpafiles,
				getFilesForTag: this.getFilesForTag,
				validateField: this.validateField,
				handleFileDownload: this.handleFileDownload,
				handleFileDelete: this.handleFileDelete,
				disableDelete: this.state.disableDelete,
				showLoader: this.props.showLoader,
				files: this.state.files,
				handleRemoveFile: this.handleRemoveFile,
				onDropFile: this.onDropFile,
				handleChange: this.handleChange,
				handleIdNumberAndTypeChange: this.handleIdNumberAndTypeChange,
				copyContactInformation: this.copyContactInformation,
				copyAddress: this.copyAddress,
				requiredFields: this.state.requiredFields,
				getRequiredDocs: this.getRequiredDocs,
				handleUpdateFileDescription: this.handleUpdateFileDescription,
				setRequiredFields: this.setRequiredFields,
			};
		}

		componentDidUpdate(prevProps, prevState) {
			if (
				prevState.mpa &&
				this.state.mpa &&
				(!isEqual(prevState.mpa, this.state.mpa) || !isEqual(prevState.files, this.state.files)) &&
				!this.state.dirty
			) {
				this.setState({ dirty: true });
				this.props.handleBlockChange(true);
			}

			if (this.state.errorMessage && this.state.isNewError) {
				setTimeout(() => {
					this.props.topRef.current.scrollIntoView({ behavior: 'smooth' });
				}, 200);
				this.setState({ isNewError: false });
			}

			// Logic to handle the mpa being updated in the loader component
			if (prevProps.mpa !== this.props.mpa) {
				this.onUpdateMpa();
			}
		}

		onUpdateMpa() {
			this.setState(
				{
					mpa: this.props.mpa,
					dirty: false,
				},
				() => {
					this.props.handleBlockChange(false);
				}
			);
		}

		handleUpdateFileDescription = (fileType, i, description) => {
			let fullFileList = cloneDeep(this.state.files);
			fullFileList[fileType][i].fileDescription = description;
			this.setState({ files: fullFileList });
		};

		copyAddress = (e, callback = noop) => {
			const {
				mpa,
				mpa: {
					businessInformation: { businessAddress },
				},
			} = this.state;
			mpa.corporateAddress = e.target.checked
				? businessAddress
				: transform(businessAddress, (acc, _, key) => (acc[key] = ''));
			this.setState({ mpa: { ...mpa } }, callback);
		};

		copyContactInformation = e => {
			const newSignerInformation = {
				...this.originalSignerInformation,
			};
			if (e.target.checked) {
				const fullName = this.state.mpa.businessContactName;
				const lastSpaceIndex = lastIndexOf(fullName, ' ');
				const [newFirstName, newLastName] = [
					fullName.substring(0, lastSpaceIndex),
					fullName.substring(lastSpaceIndex + 1),
				];
				const { firstName, lastName, phoneNumber } = this.state.mpa.signerInformation;
				this.originalSignerInformation = {
					firstName,
					lastName,
					phoneNumber,
				};
				newSignerInformation.firstName = newFirstName;
				newSignerInformation.lastName = newLastName;
				newSignerInformation.phoneNumber = this.state.mpa.businessInformation.businessPhone;
			}
			const mpa = {
				...this.state.mpa,
				signerInformation: {
					...this.state.mpa.signerInformation,
					...newSignerInformation,
				},
			};
			this.setState({ mpa });
		};

		handleIdNumberAndTypeChange = e => {
			this.handleChange(e).then(() => {
				this.setRequiredFields();
			});
		};

		setRequiredFields = () => {
			const {
				identificationType,
				identificationNumber,
				identificationIssueDate,
				identificationExpirationDate,
			} = this.state.mpa.signerInformation;
			const isIdFieldSet =
				!!identificationType || !!identificationNumber || !!identificationIssueDate || !!identificationExpirationDate;

			const ssnRequired = !isIdFieldSet;
			const idFieldsRequired = isIdFieldSet;
			const idFieldNames = [
				'IdentificationNumber',
				'IdentificationType',
				'IdentificationIssueDate',
				'IdentificationExpirationDate',
			];
			let newRequiredFields = this.state.requiredFields;
			each(this.state.requiredFields, (_value, key) => {
				if (includes(idFieldNames, key)) {
					newRequiredFields[key] = idFieldsRequired;
				}
				if (key === 'SocialSecurityNumber') {
					newRequiredFields[key] = ssnRequired;
				}
			});
			this.setState({ requiredFields: newRequiredFields });
		};

		handleChange = (e, callback = noop) => {
			let mpa = cloneDeep(this.state.mpa);
			let itemToSet, itemKey;
			if (e.target.name.indexOf('_') > -1) {
				let keyList = e.target.name.split('_');
				itemToSet = keyList.reduce((prev, curItem, idx) => {
					if (idx < keyList.length - 1) {
						return prev[curItem];
					}
					return prev;
				}, mpa);
				itemKey = keyList[keyList.length - 1];
			} else {
				itemToSet = mpa;
				itemKey = e.target.name;
			}
			let newVal = e.target.value;
			if (e.target.type && e.target.type === 'checkbox') newVal = e.target.checked;

			itemToSet[itemKey] = newVal;

			let errorListPaths;
			let errorList;
			if (this.state.showValidation && this.mpaSchema) {
				errorList = this.mpaSchema.validate(Object.assign({}, mpa));
				errorListPaths = errorList.map(error => error.path);
			}

			const newState = { mpa, errorListPaths, errorList };
			this.setState(newState, callback);

			return Promise.resolve();
		};

		handleError = err => {
			console.log('save error', err);
			this.props.showLoader(false);
			this.setState({ errorMessage: 'An error occurred: ' + err, isNewError: true });
		};

		handleMPADownload = forPrint => e => {
			// first save to make sure everything relevant will be on the downloaded document
			this.props.showLoader(true);
			if (this.state.dirty) {
				const mpa = cloneDeep(this.state.mpa);

				appService
					.saveEApp(mpa)
					.then(() => {
						this.setState({ dirty: false }, () => {
							this.props.handleBlockChange(false);
							this.props.loadAppStatus();
						});
						this.downloadMPA(forPrint);
						this.props.showLoader(false);
					})
					.catch(err => {
						this.handleError(err);
					});
			} else {
				this.downloadMPA(forPrint);
				this.props.showLoader(false);
			}
		};

		onDropFile = (fileType, acceptedFiles) => {
			let fullFileList = cloneDeep(this.state.files);
			let newFilesList = acceptedFiles.map((itm, i) => {
				return { file: itm, fileDescription: '' };
			});

			if (fullFileList[fileType]) fullFileList[fileType].push.apply(fullFileList[fileType], newFilesList);
			else fullFileList[fileType] = newFilesList;

			this.setState({ files: fullFileList });
		};

		focusField = name => {
			const elem = first(document.getElementsByName(name));
			if (!elem) return;
			elem.focus();
			elem.click();
		};

		downloadMPA(forPrint) {
			let fileName = 'mpa_' + this.state.appId + '.pdf';
			const mpa = this.state.mpa;

			appService.downloadPrefilledMPA(mpa).then(url => {
				let msg = '';
				if (url.errorMessage) {
					msg = url.errorMessage;
				} else {
					openFile(url, fileName, forPrint, forPrint);
				}
				if (msg !== '') this.setState({ errorMessage: msg });
			});
		}

		saveAndEmailApplication = () => {
			if (this.state.disableSave) {
				return;
			}
			this.setState({ disableSave: true });
			const mpa = cloneDeep(this.state.mpa);
			if (this.state.dirty) {
				this.props.showLoader(true);
				appService
					.saveEApp(mpa)
					.then(() => {
						this.props.showLoader(false);
						this.setState({ dirty: false, disableSave: false }, () => {
							this.props.handleBlockChange(false);
							this.props.loadAppStatus();
						});
						this.sendSharedFormEmail();
					})
					.catch(err => {
						this.handleError(err);
						this.setState({ disableSave: false });
					});
			} else {
				this.setState({ disableSave: false });
				this.sendSharedFormEmail();
			}
		};

		save = goToNextStep => {
			if (this.state.disableSave) {
				return;
			}
			this.setState({ disableSave: true });
			const { history } = this.props;
			const mpa = cloneDeep(this.state.mpa);
			this.props.showLoader(true);
			if (this.state.dirty) {
				appService
					.saveEApp(mpa)
					.then(this.saveFiles)
					.then(() => {
						this.props.showLoader(false);
						this.setState({ dirty: false, files: {}, disableSave: false }, async () => {
							await this.props.handleBlockChange(false);
							if (!goToNextStep) {
								return await this.props.loadMPAFromService(true);
							}
							history.push('/eapp/setup/' + this.state.appId);
						});
					})
					.catch(err => {
						this.handleError(err);
					});
			} else {
				this.props.showLoader(false);
				this.setState({ dirty: false, disableSave: false }, async () => {
					await this.props.handleBlockChange(false);
					if (!goToNextStep) return;
					history.push('/eapp/setup/' + this.state.appId);
				});
			}
		};

		saveMPAFile = (fileTags, files) => {
			if (!fileTags || !files || !files[fileTags]) {
				this.openCloseModal({
					name: modalNames.none,
				});
				return;
			}
			return appService
				.saveMerchantFiles(this.state.appId, files)
				.then(() => {
					const { dirty, mpa, disableSave } = this.state;

					if (dirty && !disableSave) {
						return appService
							.saveEApp(mpa)
							.then(this.saveFiles)
							.then(() => {
								return new Promise(resolve => {
									this.setState({ dirty: false, files: {}, disableSave: false }, () => {
										this.props
											.handleBlockChange(false)
											.then(resolve)
											.then(this.props.loadMPAFromService(true));
									});
								});
							})
							.catch(err => {
								this.handleError(err);
							});
					} else if (!disableSave) {
						return this.props.loadMPAFromService(true);
					}
				})
				.then(() => {
					const addNotification = get(this.notificationRef, 'current.addNotification', noop);
					this.openCloseModal({
						name: modalNames.none,
					});
					addNotification({
						message: 'Successfully uploaded',
						success: true,
					});
					this.setState({ errorMessage: null });
				})
				.catch(err => {
					const addNotification = get(this.notificationRef, 'current.addNotification', noop);
					this.openCloseModal({
						name: modalNames.none,
					});
					addNotification({
						message: 'Failed to upload',
						success: false,
					});
					this.props.showLoader(false);
					this.setState({ errorMessage: 'Files could not be uploaded - an error occurred: ' + err, isNewError: true });
				});
		};

		saveFiles = () => {
			const filesToSave = cloneDeep(this.state.files);
			return new Promise((resolve, reject) => {
				if (isEmpty(filesToSave) && !some(filesToSave, file => !isEmpty(file))) {
					resolve();
				}
				appService
					.saveMerchantFiles(this.state.appId, filesToSave)
					.then(resolve)
					.catch(reject);
			});
		};

		sendSharedFormEmail = () => {
			this.openCloseModal({
				name: modalNames.shareMPA,
				data: {
					appid: this.state.appId,
					merchantName: this.state.mpa.ownerName || this.state.mpa.businessContactName || this.state.mpa.dba,
					merchantEmail: this.state.mpa.businessInformation.businessEmail,
					documents: ['FidelityMPA'],
					fieldOptions: this.state.mpa.fieldOptionsRequiredForMerchant,
					dba: this.state.mpa.dba,
				},
			});
		};

		showUploadMPA = () => {
			this.openCloseModal({
				name: modalNames.fileUpload,
				data: {
					tagList: ['SignedMPA'],
					handleSave: this.saveMPAFile,
					closeModal: this.openCloseModal,
				},
			});
		};

		scrollTo = id => {
			let elem = document.getElementById(id);

			if (id.indexOf('signedmpa') > -1 || id.indexOf('signaturepages') > -1) {
				elem = document.getElementById('uploadsignedmpa');
				this.showUploadMPA();
			}

			elem && elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
		};

		getRequiredDocs = () => {
			const { mpa } = this.state;
			let requiredDocs = map(get(this.props.sidebarRef, 'current.state.appStatus.requiredDocuments', []));
			const docTypes = ['CorporateDocs'];
			const isQuebec = includes(['Quebec', 'QC'], mpa.businessInformation.businessAddress.state);
			map(docTypes, docType => {
				const isDocTypeInRequiredList = some(requiredDocs, file => file.fileTag === docType);
				if (mpa.isCanadian && !isQuebec) {
					if (!isDocTypeInRequiredList) {
						requiredDocs.push({ fileTag: docType, isComplete: false });
					}
				} else {
					requiredDocs = requiredDocs.filter(file => file.fileTag != docType);
				}
			});
			return requiredDocs;
		};
		validateField = () => '';
		handleFileDelete = (appId, fileId, fileName, fileTag) => e => {
			if (this.state.disableDelete) {
				return;
			}
			this.setState({ disableDelete: true });
			this.openCloseModal({
				name: modalNames.confirmAction,
				data: {
					question: 'Are you sure you want to delete ' + fileName + '?',
					onConfirm: this.deleteFile(appId, fileId, fileTag),
				},
			});
		};
		deleteFile = (appId, fileId, fileTag) => () => {
			this.props.showLoader(true);
			appService
				.deleteMerchantFile(appId, fileId)
				.then(() => {
					const { dirty, mpa } = this.state;
					const shouldLoadAppStatus = this.checkIfRequiredFileTag(fileTag);

					if (this.isMerchant) {
						//set from withMerchantMpa
						this.setState(prevState => {
							if (has(prevState, 'mpa.files')) {
								prevState.mpa.files = filter(prevState.mpa.files, file => file.fileId !== fileId);
								return prevState;
							}
						});
					} else if (dirty) {
						return appService
							.saveEApp(mpa)
							.then(() => {
								return this.props.loadMPAFromService(false, shouldLoadAppStatus);
							})
							.catch(err => {
								this.handleError(err);
							});
					} else {
						return this.props.loadMPAFromService(false, shouldLoadAppStatus);
					}
				})
				.then(() => {
					this.props.showLoader(false);
					const addNotification = get(this.notificationRef, 'current.addNotification', noop);
					addNotification({
						message: 'Successfully deleted',
						success: true,
					});
					this.setState({ disableDelete: false });
				})
				.catch(ex => {
					this.props.showLoader(false);
					this.setState({ downloadErrorMessage: ex, disableDelete: false });
				});
		};
		checkIfRequiredFileTag = fileTag => {
			const fileTags = fileTag.indexOf('_') > -1 ? split(fileTag, '_') : [fileTag];
			return some(this.requiredDocuments, item => some(fileTags, f => toLower(f) === toLower(item)));
		};
		openCloseModal = modalObj => {
			let state = {
				modal: modalObj,
			};
			this.setState(state);
		};
		renderShareMPA() {
			return (
				<button
					className="btn btn--med btn--primary spc--right--sml spc--bottom--sml datatooltip--200"
					onClick={this.saveAndEmailApplication}
					data-tooltip="Sending the MPA through Partner Portal is the fastest way to get it to the lead."
					disabled={this.state.disableSave}
				>
					Send Form to Merchant
				</button>
			);
		}

		renderUploadMPA() {
			return (
				<button id="uploadsignedmpa" className="btn btn--med btn--ghost spc--bottom--sml" onClick={this.showUploadMPA}>
					Upload Signed MPA
				</button>
			);
		}

		renderDownloadMPA() {
			return (
				<button
					className="btn btn--med btn--ghost spc--right--sml spc--bottom--sml datatooltip--200"
					data-tooltip="Download a prefilled MPA"
					disabled={this.props.isLoading}
					onClick={this.handleMPADownload(false)}
				>
					<i className="icon icon--xsml icon--download align--v--middle" />
				</button>
			);
		}

		renderPrintMPA() {
			return (
				<button
					className="btn btn--med btn--ghost spc--right--sml spc--bottom--sml datatooltip--150"
					data-tooltip="Print a prefilled MPA"
					disabled={this.props.isLoading}
					onClick={this.handleMPADownload(true)}
				>
					<i className="icon icon--xsml icon--print align--v--middle" />
				</button>
			);
		}

		renderMissingFields() {
			const { files, mpa, disableSave } = this.state;
			const requiredErrorList = this.mpaRequiredSchema.validate(Object.assign({}, mpa));
			const invalidErrorList = this.mpaInvalidSchema.validate(Object.assign({}, mpa));
			if (invalidErrorList.length > 0) {
				if (!disableSave) this.setState({ disableSave: true });
			} else {
				if (disableSave) this.setState({ disableSave: false });
			}
			const requiredErrorListPaths = requiredErrorList.map(error => error.path);
			const invalidErrorListPaths = invalidErrorList.map(error => error.path);
			const requiredDocs = this.getRequiredDocs();
			const incompleteDocs = filter(requiredDocs, ({ isComplete, fileTag }) => !isComplete && isEmpty(files[fileTag]));

			return incompleteDocs.length || requiredErrorListPaths.length || invalidErrorListPaths.length ? (
				<React.Fragment>
					{invalidErrorListPaths.length > 0 && (
						<div className="note note--warning spc--bottom--med">
							<ul>
								{map(invalidErrorList, (elem, i) => (
									<li key={i}>
										<div
											className="anchor"
											onClick={() => {
												let elemId = elem.path.replace(/[.]/g, '_');
												this.scrollTo(elemId + '_div');
												this.focusField(elemId);
											}}
										>
											<i className="icon icon--nano icon--text-top icon--alert spc--right--tny"></i>{' '}
											{defaultReactOutput(defaultImplicitParse(elem.message))}
										</div>
									</li>
								))}
							</ul>
						</div>
					)}
					{(incompleteDocs.length > 0 || requiredErrorListPaths.length > 0) && (
						<div>
							<div className="note note--uncompleted spc--bottom--med">
								<div className="separator--dark spc--bottom--tny">
									<div className="flex flex--primary">
										<i className="icon icon--sml icon--text-top icon--alert-circle spc--right--tny spc--bottom--tny"></i>
										<p className="type--color--text--dark spc--bottom--tny">
											The fields below are required for submitting the app; however, you can save the form without this
											information now and complete the form at a later time.
										</p>
									</div>
								</div>

								<ul className="list list--primary list--primary--dark">
									{map(requiredErrorList, (elem, i) => (
										<li className="item" key={i}>
											<div
												className="anchor"
												onClick={() => {
													let elemId = elem.path.replace(/[.]/g, '_');
													this.scrollTo(elemId + '_div');
													this.focusField(elemId);
												}}
											>
												{defaultReactOutput(defaultImplicitParse(elem.message))}
											</div>
										</li>
									))}
									{map(incompleteDocs, ({ fileTag }, index) => (
										<li className="item" key={`${fileTag}.${index}`}>
											<a
												className="anchor"
												onClick={() => this.scrollTo(toLower(fileTag).replace(/[.]/g, '_') + '_div')}
											>
												<span className="type--wgt--bold"> {startCase(fileTag)}</span>
											</a>
										</li>
									))}
								</ul>
							</div>
						</div>
					)}
				</React.Fragment>
			) : null;
		}

		renderHeader() {
			return (
				<React.Fragment>
					<Notification ref={this.notificationRef} />
					<div className="spc--bottom--med">
						<ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
					</div>
				</React.Fragment>
			);
		}

		renderErrors() {
			const { errorMessage } = this.state;
			if (!errorMessage) return null;
			return <div className="note note--warning">{errorMessage}</div>;
		}

		renderMpaTitle() {
			const { mpa, appId } = this.state;
			const merchantDba = get(mpa, 'dba');
			if (!merchantDba || !appId) return null;
			return (
				<header className="header spc--bottom--sml">
					<div className="header__title__wrap">
						<div className="header__title">
							<span>
								{merchantDba} - {appId}
							</span>
						</div>
					</div>
				</header>
			);
		}

		renderAppSummary() {
			const { mpa } = this.state;
			return <AppSummary app={mpa} showLeadDetailsLink={!!mpa.leadId} />;
		}

		renderFooter() {
			const { disableSave } = this.state;
			return (
				<div>
					{this.renderMissingFields()}
					<div className="type--right">
						<button
							className="btn btn--primary btn--med spc--right--tny"
							disabled={disableSave}
							onClick={() => this.save(false)}
						>
							Save
						</button>
						<button className="btn btn--primary btn--med" disabled={disableSave} onClick={() => this.save(true)}>
							Save and Next Step
						</button>
					</div>
				</div>
			);
		}
		renderMpa() {
			return <Component {...this.mpaData} />;
		}
		render() {
			const { mpa } = this.state;

			return (
				<React.Fragment>
					{this.renderHeader()}
					{this.renderErrors()}
					{mpa && (
						<div className="align--h--center l--content--med">
							{this.renderMpaTitle()}
							{this.renderAppSummary()}
							<div className="header header--mpa">
								<div className="header__title spc--bottom--sml">
									<h3>MPA</h3>
								</div>
								<div>{this.renderDownloadMPA()}</div>
								<div>{this.renderPrintMPA()}</div>
								<div>{this.renderShareMPA()}</div>
								<div>{this.renderUploadMPA()}</div>
							</div>
							{this.renderMpa()}
							{this.renderFooter()}
						</div>
					)}
				</React.Fragment>
			);
		}
	}
	ComponentWithMpa.defaultProps = {
		handleBlockChange: () => Promise.resolve(),
	};
	return ComponentWithMpa;
}
