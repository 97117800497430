import React, { Fragment, Component, createRef } from 'react';
import PropTypes from 'prop-types';
import {
	set,
	isEmpty,
	includes,
	replace,
	isArray,
	some,
	transform,
	map,
	filter,
	get,
	noop,
	cloneDeep,
	each,
	repeat,
	isEqual,
	isFunction,
	find,
} from 'lodash';
import { appService } from '../../services/appService';
import { AddressComponent } from '../../common/components/address';
import SingleDatePicker from '../../common/filters/date-picker/single-date-picker';
import { OwnershipDropdownComponent } from '../../common/components/ownership-dropdown';
import { FileDropzoneComponent } from '../../common/components/file-dropzone';
import { canPreviewFile } from '../../common/utilities/canPreviewFile';
import { handleFileDownload } from '../../common/utilities/commonFileHandlingMethods';
import { modalNames } from '../../common/components/modal-wrapper';
import { NumericFormat } from 'react-number-format';
import KeyCodes from '../../common/components/react-data-grid/src/KeyCodes';

const required = (
	<span data-tooltip="Required" className="type--color--primary">
		{' '}
		*
	</span>
);

class AchBaseClass extends Component {
	constructor(props, requiredFields, requiredDocs, conditionalRequiredFields = []) {
		super(props);

		this.requiredFields = requiredFields;
		this.conditionalRequiredFields = conditionalRequiredFields;
		this.requiredDocs = requiredDocs;
		this.state = this.initialState;
		this.handleFileDownload = handleFileDownload.bind(this);
		this.maskedFieldRefs = createRef();
		this.maskedFieldRefs.current = {};
	}

	get achWorksheetFieldNames() {
		return {
			agentName: 'Agent Name',
			dba: 'Business DBA name',
			corporateName: 'Business legal name',
			goodsOrServicesDescription: 'Describe the business',
			taxID: 'EINFederal Tax ID',
			businessStartDate: 'Year business formed',
			businessInformation_ownershipType: 'Business type',
			businessInformation_website: 'Business website',
			businessInformation_businessAddress_streetAddress: 'Business address',
			businessInformation_businessAddress_city: 'City',
			businessInformation_businessAddress_state: 'State',
			businessInformation_businessAddress_zip: 'Zip',
			businessInformation_businessPhone: 'Business Phone',
			corporateAddress_streetAddress: 'Mailing address',
			corporateAddress_city: 'City_2',
			corporateAddress_state: 'State_2',
			corporateAddress_zip: 'Zip_2',
			businessContactName: 'Primary contact',
			contactPhoneNumber: 'Contact Phone',
			businessInformation_businessEmail: 'Email Address',
			signerInformation_firstName: 'Name',
			signerInformation_ownershipPercentage: 'of Ownership',
			signerInformation_phoneNumber: 'Home',
			signerInformation_socialSecurityNumber: 'SS',
			signerInformation_address_streetAddress: 'Home Address',
			signerInformation_address_city: 'City_3',
			signerInformation_address_state: 'State_3',
			signerInformation_address_zip: 'Zip_3',
			signerInformation_email: 'Signer email address',
			signerInformation_title: 'Title',
			bankAccountInformation_bankName: 'Name on bank account',
			bankAccountInformation_routingNumber: 'ABA number',
			bankAccountInformation_accountNumber: 'DDA number',
			generalTransactionInfo_maxSingle: 'Largest Payment',
			generalTransactionInfo_maxDaily: 'Daily average',
			generalTransactionInfo_maxDailyAmount: 'Largest daily payment',
			generalTransactionInfo_maxPeriod: 'Monthly average',
			generalTransactionInfo_maxPeriodAmount: 'Largest monthly payment',
			generalTransactionInfo_averageTransactionAmount: 'Average transaction amount',
		};
	}

	get initialState() {
		return {
			isNewError: false,
			errorMessage: null,
			errorList: [],
			errorListPaths: [],
			enableSecondaryBankAccountInfo: false,
			enableAlternativeBankAccountInfo: false,
			localFiles: {},
			sameAsBusiness: false,
			modal: {
				name: modalNames.none,
				data: null,
			},
			fields: {
				files: [],
				appId: 0,
				mid: '',
				leadId: '',
				agentId: 0,
				businessContactName: '',
				ownerName: '',
				dba: '',
				processorName: '',
				contactPhoneNumber: '',
				businessStartDate: '',
				corporateName: '',
				goodsOrServicesDescription: '',
				taxID: '',
				statusTitle: '',
				isNewAccount: false,
				isAdditionalLocation: false,
				accountNotes: '',
				submittedBy: 0,
				isPciCompliant: false,
				telemarketerList: [],
				telemarketerEmail: '',
				telemarketerId: 0,
				hasExistingFidelityAccount: null,
				existingAccountDba: '',
				generalTransactionInfo: {
					maxSingle: null,
					maxDaily: null,
					maxDailyAmount: null,
					maxPeriod: null,
					maxPeriodAmount: null,
					averageTransactionAmount: null,
				},
				bankAccountInformation: {
					bankName: '',
					routingNumber: '',
					accountNumber: '',
					secondaryBankName: '',
					secondaryRoutingNumber: '',
					secondaryAccountNumber: '',
					alternateBankName: '',
					alternateRoutingNumber: '',
					alternateAccountNumber: '',
				},
				signerInformation: {
					ownershipPercentage: null,
					socialSecurityNumber: null,
					dateOfBirth: '',
					firstName: null,
					lastName: null,
					email: null,
					title: null,
					phoneNumber: null,
					cellPhoneNumber: null,
					address: {
						streetAddress: '',
						city: '',
						state: '',
						zip: '',
					},
				},
				businessInformation: {
					businessAddress: {
						streetAddress: '',
						city: '',
						state: '',
						zip: '',
						country: '',
					},
					ownershipType: '',
					businessEmail: '',
					businessPhone: '',
					businessFax: '',
					website: '',
				},
				corporateAddress: {
					streetAddress: '',
					city: '',
					state: '',
					zip: '',
					country: '',
				},
			},
		};
	}

	createFormSnapshotObj = () => {
		const { fields, localFiles } = this.state;
		const formSnapshot = {};
		formSnapshot.fields = cloneDeep(fields);
		formSnapshot.localFiles = cloneDeep(localFiles);
		return formSnapshot;
	};

	createFormSnapshot = () => {
		const formSnapshot = this.createFormSnapshotObj();
		this.formSnapshot = JSON.stringify(formSnapshot);
	};

	get isDirty() {
		const current = this.createFormSnapshotObj();
		return this.formSnapshot !== JSON.stringify(current);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.conditionalRequiredFields &&
			(!isEqual(prevState.fields, this.state.fields) ||
				!isEqual(prevState.enableSecondaryBankAccountInfo, this.state.enableSecondaryBankAccountInfo) ||
				!isEqual(prevState.enableAlternativeBankAccountInfo, this.state.enableAlternativeBankAccountInfo))
		) {
			const requiredFieldsCopy = cloneDeep(this.requiredFields);
			each(this.conditionalRequiredFields, crf => {
				if (isFunction(crf.condition) && crf.condition(this.state)) {
					each(crf.fields, field => {
						if (!this.requiredFields.includes(field)) {
							this.requiredFields.push(field);
						}
					});
				} else if (isFunction(crf.condition)) {
					each(crf.fields, field => {
						this.requiredFields = this.requiredFields.filter(item => item !== field);
					});
				}
			});
			if (!isEqual(requiredFieldsCopy, this.requiredFields)) {
				if (this.state.showValidation && this.mpaSchema) {
					const { fields, enableSecondaryBankAccountInfo, enableAlternativeBankAccountInfo } = this.state;
					const errorList = this.mpaSchema.validate(
						Object.assign({}, { ...fields, enableSecondaryBankAccountInfo, enableAlternativeBankAccountInfo })
					);
					const errorListPaths = errorList.map(error => error.path);
					this.setState({ errorList, errorListPaths });
				} else {
					this.forceUpdate();
				}
			}
		}
	}

	validateDocuments = () => {
		const {
			fields: { files },
			localFiles,
		} = this.state;
		return filter(
			this.requiredDocs,
			fileTag => isEmpty(localFiles[fileTag]) && !some(files, file => get(file, 'fileTag') === fileTag)
		);
	};

	handleError = err => {
		console.log('save error', err);
		this.props.showLoader(false);
		this.setState({ errorMessage: 'An error occurred: ' + err, isNewError: true }, () =>
			setTimeout(() => this.scrollTo('api-errors'), 0)
		);
	};

	renderErrors() {
		const { errorMessage } = this.state;
		if (!errorMessage) return null;
		return (
			<div className="note note--warning spc--bottom--med" id="api-errors">
				{errorMessage}
			</div>
		);
	}

	saveFiles = () => {
		const filesToSave = cloneDeep(this.state.localFiles);
		return new Promise((resolve, reject) => {
			appService
				.saveMerchantFiles(this.state.appId || this.state.fields.appId, filesToSave)
				.then(resolve)
				.catch(err => {
					console.log('save files error', err);
					this.props.showLoader(false);
					this.setState({
						errorMessage: 'MPA form saved, but files could not be uploaded - an error occurred: ' + err,
						isNewError: true,
					});
					reject(err);
				});
		});
	};

	renderNumberFormat = ({
		name,
		value,
		label,
		decimalScale = 0,
		allowLeadingZeros = true,
		tooltip = null,
		validateFieldsArr,
		disableField = false,
		className = '',
		renderMaskedInput = false,
	}) => {
		const disabled = disableField;

		const componentProps = {
			name,
			value,
			id: name,
			decimalScale,
			allowLeadingZeros,
			inputType: 'numeric',
			allowNegative: false,
			placeholder: label,
			className: `input ${renderMaskedInput ? 'display--n' : ''} input--med${this.validateField(
				validateFieldsArr ? validateFieldsArr : name
			)}`,
			onValueChange: values =>
				this.handleChange({ target: { name, value: allowLeadingZeros ? values.value : values.floatValue } }),
			disabled,
		};

		if (allowLeadingZeros) {
			componentProps.isNumericString = true;
		}

		return (
			<div className="col col-sml-12 col-lrg-6 spc--bottom--med">
				{this.renderLabel({ tooltip, label, name, disabled })}
				<div id={`${name}_div`} className={`input--masked ${className}`}>
					{renderMaskedInput ? this.renderMaskedInput(componentProps) : <NumericFormat {...componentProps} />}
				</div>
			</div>
		);
	};

	onBlurMaskedInput = fieldKey => {
		this.maskedFieldRefs.current[fieldKey].classList.add('display--n');
		this.maskedFieldRefs.current[`${fieldKey}_mask`].classList.remove('display--n');
	};

	onFocusMaskedInput = fieldKey => {
		this.maskedFieldRefs.current[`${fieldKey}_mask`].classList.add('display--n');
		this.maskedFieldRefs.current[fieldKey].classList.remove('display--n');
		this.maskedFieldRefs.current[fieldKey].focus();
	};

	renderMaskedInput = componentProps => {
		const { name, value } = componentProps;
		componentProps.getInputRef = el => (this.maskedFieldRefs.current[name] = el);
		componentProps.onBlur = () => this.onBlurMaskedInput(name);

		return (
			<Fragment>
				<span>
					<input
						type="text"
						className={`input input--med${this.validateField(name)}`}
						placeholder={componentProps.placeholder}
						id={name}
						name={name}
						value={value ? repeat('X', value.length) : ''}
						onFocus={() => this.onFocusMaskedInput(name)}
						ref={el => (this.maskedFieldRefs.current[`${name}_mask`] = el)}
						disabled={componentProps.disabled}
					/>
				</span>
				<span>
					<NumericFormat {...componentProps} />
				</span>
			</Fragment>
		);
	};

	validateField = name => {
		const { errorListPaths } = this.state;
		const invalidClassName = ' is-invalid';

		if (
			(isArray(name) && some(name, item => includes(errorListPaths, replace(item, /_/gi, '.')))) ||
			includes(errorListPaths, replace(name, /_/gi, '.'))
		) {
			return invalidClassName;
		}

		return '';
	};

	scrollTo = id => {
		let elem = document.getElementById(`${id}_div`);
		if (!elem) {
			id = id.replace(/[.]/g, '_');
			elem = document.getElementById(`${id}_div`);
		}
		if (!elem) {
			elem = document.getElementById(`${id}`);
		}
		if (id.indexOf('signedmpa') > -1 || id.indexOf('signaturepages') > -1) {
			elem = document.getElementById('uploadsignedmpa');
			this.showUploadMPA();
		}
		elem && elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};

	focusField = id => {
		let elem = document.getElementById(id);
		if (!elem) {
			id = id.replace(/[.]/g, '_');
			elem = document.getElementById(`${id}`);
		}
		elem && elem.focus();
	};

	onErrorClick = elName => {
		let elementId = elName.path;
		this.scrollTo(elementId);
		this.focusField(elementId);
	};

	handleChange = ({ target: { name, value, checked, type } }, index, callback = noop) => {
		const { fields, enableSecondaryBankAccountInfo, enableAlternativeBankAccountInfo, sameAsBusiness } = this.state;
		const newState = { fields: { ...fields } };
		let newValue;

		if (type === 'checkbox') {
			newValue = checked;
		} else if (type === 'radio') {
		} else {
			newValue = value;
		}

		if (name.indexOf('_') > -1) {
			name = replace(name, /_/g, '.');
		}
		set(newState.fields, name, newValue);

		if (sameAsBusiness && name.indexOf('businessAddress') > -1) {
			newState.fields.corporateAddress = cloneDeep(newState.fields.businessInformation.businessAddress);
		}

		let errorListPaths;
		let errorList;
		if (this.state.showValidation && this.mpaSchema) {
			errorList = this.mpaSchema.validate(
				Object.assign({}, { ...newState.fields, enableSecondaryBankAccountInfo, enableAlternativeBankAccountInfo })
			);
			errorListPaths = errorList.map(error => error.path);
		}

		this.setState(
			{
				fields: {
					...newState.fields,
				},
				errorList,
				errorListPaths,
			},
			callback
		);
	};

	handleTelemarketerChange = e => {
		const telemarketer = find(this.state.fields.telemarketerList, x => x.agentName === e.target.value);
		let telemarketerEmail = get(telemarketer, 'agentName', '');
		let telemarketerId = get(telemarketer, 'agentId', 0);
		this.setState({ fields: { ...this.state.fields, telemarketerEmail, telemarketerId } });
	};

	copyAddress = e => {
		const {
			fields: {
				businessInformation: { businessAddress },
			},
		} = this.state;
		const corporateAddress = e.target.checked
			? businessAddress
			: transform(businessAddress, (acc, _, key) => (acc[key] = ''));
		this.setState({ fields: { ...this.state.fields, corporateAddress }, sameAsBusiness: e.target.checked });
	};

	checkIfShouldDisplayAsterisk = name => {
		return some(this.requiredFields, item => item === name);
	};

	renderLabel = ({ tooltip, label, name, disabled }) => {
		return tooltip ? (
			<div className="flex--primary">
				<label htmlFor={name} className="label spc--right--tny">
					{label}
					{this.checkIfShouldDisplayAsterisk(name) && !disabled && required}
				</label>
				<i className="icon icon--tiny icon--info datatooltip--200 datatooltip--right" data-tooltip={tooltip}></i>
			</div>
		) : (
			<label htmlFor={name} className="label">
				{label}
				{this.checkIfShouldDisplayAsterisk(name) && !disabled && required}
			</label>
		);
	};

	enableBankAccountInformation = e => {
		const {
			target: { name, checked },
		} = e;
		if (name === 'sameAsBankAccountInformationSec') {
			this.setState(prevState => {
				const newState = cloneDeep(prevState);
				newState.enableSecondaryBankAccountInfo = checked;
				if (!checked) {
					newState.fields.secondaryBankName = '';
					newState.fields.secondaryRoutingNumber = '';
					newState.fields.secondaryAccountNumber = '';
				}
				return newState;
			});
		} else {
			this.setState(prevState => {
				const newState = cloneDeep(prevState);
				newState.enableAlternativeBankAccountInfo = checked;
				if (!checked) {
					newState.fields.alternateBankName = '';
					newState.fields.alternateRoutingNumber = '';
					newState.fields.alternateAccountNumber = '';
				}
				return newState;
			});
		}
	};

	renderField = (name, value, label, tooltip = '', disableField = false, className = '', allowZeroValue = false) => {
		const disabled = disableField;
		const containerClassName = 'col col-sml-12 col-lrg-6 spc--bottom--med';

		return (
			<div id={`${name}_div`} className={`${containerClassName}`}>
				{this.renderLabel({ tooltip, label, name, disabled })}
				<input
					type="text"
					className={`input input--med${this.validateField(name)} ${className}`}
					id={name}
					name={name}
					value={allowZeroValue && value === 0 ? 0 : value || ''}
					placeholder={label}
					onChange={this.handleChange}
					disabled={disabled}
				/>
			</div>
		);
	};
	handleYesNoChange = (e, defaultValues, defaultValue) => {
		const newState = { fields: Object.assign({}, this.state.fields, { [e.target.name]: e.target.checked }) };

		if (e.target.checked === defaultValue) {
			each(defaultValues, (name, value) => {
				newState.fields[name] = value;
			});
		}

		this.setState(newState);
	};
	renderBusinessInfo() {
		const {
			fields: {
				dba,
				taxID,
				corporateName,
				businessStartDate,
				businessInformation: { website, businessFax, businessPhone, businessEmail, businessAddress, ownershipType },
				contactPhoneNumber,
				businessContactName,
				goodsOrServicesDescription,
				telemarketerList,
				telemarketerEmail,
				hasExistingFidelityAccount,
				existingAccountDba,
			},
		} = this.state;

		return (
			<Fragment>
				<div className="row grid-align-middle">
					{this.renderField('corporateName', corporateName, 'Corporate Name')}
					{this.renderField('dba', dba, 'DBA')}
					{this.renderRep2Field(telemarketerList, telemarketerEmail)}
					{this.renderField('contactPhoneNumber', contactPhoneNumber, 'Contact Phone Number')}
					{this.renderField('businessContactName', businessContactName, 'Contact Name')}
					{this.renderField(
						'businessInformation.businessEmail',
						businessEmail,
						'Contact Email',
						'Separate multiple email addresses with a comma'
					)}
					{this.renderField('businessInformation.businessPhone', businessPhone, 'Business Phone Number')}
					{this.renderField('businessInformation.businessFax', businessFax, 'Business Fax Number')}
					{this.renderField('goodsOrServicesDescription', goodsOrServicesDescription, 'Goods/Services Sold')}
					{this.renderField('businessInformation.website', website, 'Website')}
					{this.renderField('taxID', taxID, 'Tax ID (9 digits)')}
					<div id="businessStartDate_div" className="col col-sml-12 col-med-6 spc--bottom--med">
						<label className="label ">
							Business Start Date{this.checkIfShouldDisplayAsterisk('businessStartDate') && required}{' '}
						</label>
						<SingleDatePicker
							name={'businessStartDate'}
							onChange={date =>
								this.handleChange({ target: { name: 'businessStartDate', value: date, checked: null, type: null } })
							}
							validateField={() => this.validateField('businessStartDate')}
							value={businessStartDate}
						/>
					</div>
					<div className="col col-sml-12 col-lrg-6 spc--bottom--med">
						<label className="label">
							Business Type {this.checkIfShouldDisplayAsterisk('businessInformation.ownershipType') && required}
						</label>
						<OwnershipDropdownComponent
							className={`input input--med input--select${this.validateField('businessInformation.ownershipType')}`}
							id="businessInformation_ownershipType"
							value={ownershipType}
							onChange={this.handleChange}
							ownershipOptions={[
								{ value: 'Unknown', label: 'Please select' },
								{ value: 'Partnership', label: 'Partnership' },
								{ value: 'Corporation', label: 'Corporation' },
								{ value: 'SoleOwnership', label: 'Sole Ownership' },
								{ value: 'LLC', label: 'LLC' },
								{ value: 'NonProfit', label: 'Non Profit / Tax Exempt' },
								{ value: 'Other', label: 'Other' },
							]}
						/>
					</div>
					<div className="col col-sml-12 col-med-12">
						<AddressComponent
							streetPrefix="Business"
							requiredFields={this.requiredFields}
							validateField={this.validateField}
							address={businessAddress}
							namePrefix={'businessInformation_businessAddress'}
							onChange={this.handleChange}
							isCanada={false}
						/>
					</div>
					{this.renderHasExistingFidelityAccount(hasExistingFidelityAccount, existingAccountDba)}
				</div>
			</Fragment>
		);
	}

	renderMailingInfo() {
		const {
			fields: { corporateAddress },
		} = this.state;
		const sameAsBusiness = get(this.state, 'sameAsBusiness', false);
		return (
			<div className={``}>
				<div className="row grid-align-middle spc--bottom--sml">
					<div className="col col-sml-12 col-lrg-12">
						<div className="form__field">
							<input
								id="same-as"
								type="checkbox"
								className="input--check"
								checked={sameAsBusiness}
								onClick={this.copyAddress}
							/>
							<label htmlFor="same-as" className="label">
								Same as Business Address
							</label>
						</div>
					</div>
				</div>
				<fieldset disabled={sameAsBusiness}>
					<AddressComponent
						requiredFields={this.requiredFields}
						validateField={this.validateField}
						streetPrefix="Business Mailing"
						address={corporateAddress}
						namePrefix={'corporateAddress'}
						onChange={this.handleChange}
						isCanada={false}
					/>
				</fieldset>
			</div>
		);
	}

	renderBankingInfo() {
		const {
			enableSecondaryBankAccountInfo,
			enableAlternativeBankAccountInfo,
			fields: { bankAccountInformation },
		} = this.state;
		return (
			<div className="row grid-align-middle">
				{this.renderField('bankAccountInformation.bankName', bankAccountInformation.bankName, 'Bank Name')}

				{this.renderNumberFormat({
					name: 'bankAccountInformation.accountNumber',
					value: bankAccountInformation.accountNumber,
					label: 'Bank Account #',
					renderMaskedInput: true,
				})}

				{this.renderNumberFormat({
					name: 'bankAccountInformation.routingNumber',
					value: bankAccountInformation.routingNumber,
					label: 'Bank Routing #',
					renderMaskedInput: true,
				})}
				<div className="col col-sml-12 col-lrg-12">
					<div className="form__field">
						<input
							name="sameAsBankAccountInformationSec"
							id="sameAsBankAccountInformationSec"
							type="checkbox"
							className="input--check"
							checked={enableSecondaryBankAccountInfo}
							onClick={this.enableBankAccountInformation}
						/>
						<label htmlFor="sameAsBankAccountInformationSec" className="label">
							Use a different account for fees
						</label>
					</div>
				</div>
				{this.renderField(
					'bankAccountInformation.secondaryBankName',
					bankAccountInformation.secondaryBankName,
					'Bank Name',
					'',
					!enableSecondaryBankAccountInfo
				)}
				{this.renderNumberFormat({
					name: 'bankAccountInformation.secondaryAccountNumber',
					value: bankAccountInformation.secondaryAccountNumber,
					label: 'Bank Account #',
					renderMaskedInput: true,
					disableField: !enableSecondaryBankAccountInfo,
				})}
				{this.renderNumberFormat({
					name: 'bankAccountInformation.secondaryRoutingNumber',
					value: bankAccountInformation.secondaryRoutingNumber,
					label: 'Bank Routing #',
					renderMaskedInput: true,
					disableField: !enableSecondaryBankAccountInfo,
				})}
				<div className="col col-sml-12 col-lrg-12">
					<div className="form__field">
						<input
							name="sameAsBankAccountInformationAlt"
							id="sameAsBankAccountInformationAlt"
							type="checkbox"
							className="input--check"
							checked={enableAlternativeBankAccountInfo}
							onClick={this.enableBankAccountInformation}
						/>
						<label htmlFor="sameAsBankAccountInformationAlt" className="label">
							Use a different account for bounced checks
						</label>
					</div>
				</div>
				{this.renderField(
					'bankAccountInformation.alternateBankName',
					bankAccountInformation.alternateBankName,
					'Bank Name',
					'',
					!enableAlternativeBankAccountInfo
				)}
				{this.renderNumberFormat({
					name: 'bankAccountInformation.alternateAccountNumber',
					value: bankAccountInformation.alternateAccountNumber,
					label: 'Bank Account #',
					renderMaskedInput: true,
					disableField: !enableAlternativeBankAccountInfo,
				})}
				{this.renderNumberFormat({
					name: 'bankAccountInformation.alternateRoutingNumber',
					value: bankAccountInformation.alternateRoutingNumber,
					label: 'Bank Routing #',
					renderMaskedInput: true,
					disableField: !enableAlternativeBankAccountInfo,
				})}
			</div>
		);
	}

	renderSignerInformation() {
		const {
			fields: {
				signerInformation: {
					firstName,
					lastName,
					phoneNumber,
					cellPhoneNumber,
					socialSecurityNumber,
					dateOfBirth,
					address,
					ownershipPercentage,
					email,
					title,
				},
			},
		} = this.state;

		return (
			<Fragment>
				<div className="row grid-align-middle">
					{this.renderField('signerInformation.firstName', firstName, 'Signer First Name')}
					{this.renderField('signerInformation.lastName', lastName, 'Signer Last Name')}
				</div>

				<AddressComponent
					requiredFields={this.requiredFields}
					validateField={this.validateField}
					streetPrefix="Signer Home"
					address={address}
					namePrefix={'signerInformation_address'}
					onChange={this.handleChange}
					isCanada={false}
				/>
				<div className="row">
					{this.renderField('signerInformation.phoneNumber', phoneNumber, 'Phone Number')}
					{this.renderField('signerInformation.cellPhoneNumber', cellPhoneNumber, 'Signer Cell Number')}
					{this.renderField('signerInformation.email', email, 'Signer Email')}
					{this.renderField('signerInformation.title', title, 'Signer Title')}
					{this.renderField('signerInformation.socialSecurityNumber', socialSecurityNumber, 'Social Security Number')}
					{this.renderField(
						'signerInformation.ownershipPercentage',
						ownershipPercentage,
						'Ownership Percentage',
						undefined,
						undefined,
						undefined,
						true
					)}

					<div className="col col-sml-12 col-lrg-6">
						<div id="signerInformation.dateOfBirth_div" className={`form__field spc--bottom--med`}>
							<label className="label">
								Date of Birth{this.checkIfShouldDisplayAsterisk('signerInformation.dateOfBirth') && required}{' '}
							</label>
							<SingleDatePicker
								name={'signerInformation.dateOfBirth'}
								id={'signerInformation.dateOfBirth'}
								className="fs-mask"
								onChange={date =>
									this.handleChange({
										target: { name: 'signerInformation.dateOfBirth', value: date, checked: null, type: null },
									})
								}
								validateField={() => this.validateField('signerInformation.dateOfBirth')}
								value={dateOfBirth}
							/>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	getFilesForTag(tag) {
		const {
			fields: { files },
		} = this.state;
		const fullFileList = files;
		let matchTag = [];

		if (!fullFileList) {
			return null;
		}

		if (Array.isArray(tag)) {
			matchTag = tag;
		} else {
			matchTag.push(tag);
		}
		if (matchTag.includes('BankStatements')) matchTag.push('BankingStatement'); //this line can be removed in a year from now - sweinstock 4/02/2024
		return filter(fullFileList, file => {
			const fileTag = file.fileTag.split('_');
			return fileTag.filter((ft, i) => matchTag.includes(ft)).length > 0;
		});
	}

	renderFiles(files) {
		if (isEmpty(files)) return null;
		return map(files, (file, idx) => (
			<div key={idx} className="spc--bottom--sml">
				<div className="input input--med input--fake input--fake--file spc--bottom--tny">
					<i className="icon icon--xsml icon--files align--v--middle spc--right--xsml"></i> {file.fileName}
				</div>
			</div>
		));
	}

	handleFileDelete = (appId, fileId, fileName, fileTag) => e => {
		this.openCloseModal({
			name: modalNames.confirmAction,
			data: {
				question: 'Are you sure you want to delete ' + fileName + '?',
				onConfirm: this.deleteFile(appId, fileId, fileTag),
			},
		});
	};

	openCloseModal = modalObj => {
		let state = {
			modal: modalObj,
		};
		this.setState(state);
	};

	deleteFile = (appId, fileId, fileTag) => () => {
		this.props.showLoader(true);
		appService
			.deleteMerchantFile(appId, fileId)
			.then(() => {
				const { fields } = this.state;
				this.fetchData().then(fetchedData => {
					fields.files = fetchedData.files;
					this.setState({ fields }, () => {
						this.props.showLoader(false);
						const addNotification = get(this.notificationRef, 'current.addNotification', noop);
						addNotification({
							message: 'Successfully deleted',
							success: true,
						});
					});
				});
			})
			.catch(ex => {
				this.props.showLoader(false);
				this.setState({ downloadErrorMessage: ex });
			});
	};

	renderFilesForTag(tag) {
		let files = this.getFilesForTag(tag);
		if (files && files.length > 0) {
			return (
				<React.Fragment>
					<label className="label">Already Uploaded:</label>
					<div className="table--files__wrap">
						<table className="table--files">
							<tbody>
								{files.map((file, idx) => {
									return (
										<tr key={idx}>
											<td>
												<p className="table--files__name">{file.fileName}</p>
											</td>
											<td>
												{canPreviewFile(file.fileName) && (
													<button
														className="btn btn--med btn--icon datatooltip--left"
														data-tooltip="View"
														onClick={this.handleFileDownload(
															file.parentId,
															file.fileId,
															file.fileName,
															this.props.showLoader
														)}
														disabled={this.props.isLoading}
													>
														<i className="icon icon--xsml icon--view align--v--sub"></i>
													</button>
												)}
												<button
													className="btn btn--med btn--icon datatooltip--left"
													data-tooltip="Download"
													onClick={this.handleFileDownload(
														file.parentId,
														file.fileId,
														file.fileName,
														this.props.showLoader,
														true
													)}
													disabled={this.props.isLoading}
													download
												>
													<i className="icon icon--xsml icon--download align--v--sub"></i>
												</button>
												<button
													className="btn btn--med btn--icon datatooltip--left"
													data-tooltip="Delete"
													onClick={this.handleFileDelete(file.parentId, file.fileId, file.fileName, file.fileTag)}
													disabled={this.props.isLoading || this.state.disableSave}
												>
													<i className="icon icon--tiny icon--wastebasket align--v--sub"></i>
												</button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</React.Fragment>
			);
		}
	}

	onDropFile = (fileType, files) => {
		const { localFiles } = this.state;
		const fullFileList = cloneDeep(localFiles);
		let newFilesList = files.map((itm, i) => {
			return { file: itm, fileDescription: '' };
		});

		if (fullFileList[fileType]) fullFileList[fileType].push(...newFilesList);
		else fullFileList[fileType] = newFilesList;
		this.setState({ localFiles: fullFileList });
	};

	onEnterKeyDownHandler = (event, onClickHandler) => {
		if (event.keyCode === KeyCodes.Enter) onClickHandler();
	};

	handleRemoveFile = (fileType, i, callback = noop) => {
		const { localFiles } = this.state;
		const fullFileList = cloneDeep(localFiles);
		fullFileList[fileType].splice(i, 1);
		this.setState({ localFiles: fullFileList });
	};

	renderFileDropZone(tag) {
		const { localFiles } = this.state;
		return (
			<FileDropzoneComponent
				tag={tag}
				multiple={true}
				fileList={localFiles}
				onDrop={this.onDropFile}
				onRemoveFile={this.handleRemoveFile}
				validateFileSize={false}
			/>
		);
	}

	checkIfRequiredFileTag = fileTag => {
		return some(this.requiredDocs, tag => tag === fileTag);
	};

	renderUploadDocumentRow(tag, label, tooltip, showSeparator = true, id = null) {
		const required = this.checkIfRequiredFileTag(tag);
		return (
			<React.Fragment>
				<div className="row">
					<div className={`col col-sml-12 col-lrg-6 spc--bottom--sml${required ? ' required' : ''}`} id={id}>
						<label className="display--b type--color--text--dark type--wgt--bold">
							{label}{' '}
							{tooltip && (
								<i className="icon icon--tiny align--v--middle icon--info datatooltip--200" data-tooltip={tooltip} />
							)}
						</label>
					</div>
					<div className="col col-sml-12 col-lrg-6 spc--bottom--med">
						<div className="form__field">
							<div className="file-upload--sml">{this.renderFileDropZone(tag)}</div>
						</div>
						<div className="card--no-expand">{this.renderFilesForTag(tag)}</div>
					</div>
				</div>
				{showSeparator && <div className="separator separator--grey1 spc--bottom--sml" />}
			</React.Fragment>
		);
	}

	renderOtherNotes() {
		const {
			fields: { accountNotes },
		} = this.state;
		return (
			<textarea
				placeholder="Notes"
				className="input input--textarea spc--bottom--med"
				cols={50}
				rows={5}
				name="accountNotes"
				value={accountNotes}
				onChange={this.handleChange}
			></textarea>
		);
	}
}

AchBaseClass.propTypes = {
	match: PropTypes.object,
	history: PropTypes.object,
	handleBlockChange: PropTypes.func,
	showLoader: PropTypes.func,
	isLoading: PropTypes.func,
};

export default AchBaseClass;
